/**
=========================================================
* Material Dashboard 2 React - v2.2.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-dashboard-react
* Copyright 2023 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/


import { Formik, Form, Field, ErrorMessage } from 'formik';
import * as Yup from 'yup';
import { toast } from 'react-toastify';
// import { useNavigate } from 'react-router-dom';
// Material Dashboard 2 React components
import Container from '@mui/material/Container';
import Paper from '@mui/material/Paper';
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import MDButton from "components/MDButton";
import MDInput from 'components/MDInput';
// Material Dashboard 2 React example components
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
// import Footer from "examples/Footer";
import { Grid } from '@mui/material';
import { useEffect, useState } from 'react';
import { useConfig } from "../../../config"
// import { useUser } from "ZustandState/useUser";
import { useMaterialUIController } from 'context';
import { useSandbox } from 'ZustandState/useSanbox';
import { Autocomplete, TextField, FormControl, FormHelperText } from '@mui/material';
import { useNavigate } from 'react-router-dom';
import { useLocation } from 'react-router-dom';
// Data
function editSales() {
    const sandbox = useSandbox((state) => state.sandbox)
    const navigate = useNavigate()
    const location = useLocation()
    const { email, merchantsList, name } = location.state
    const [controller] = useMaterialUIController()
    const { darkMode } = controller
    const adminEmail = localStorage.getItem('admin_email')
    const adminApiKey = localStorage.getItem('admin_apiKey')
    const { apiUrl } = useConfig()
    const [merchants, setMerchants] = useState([])
    const validationSchema = Yup.object({
        username: Yup.string(),
    });

    const initialValues = {
        emailId: adminEmail || '',
        apiKey: adminApiKey || '',
        email_Id: email,
        username: name || '',
        merchant_id: merchantsList
    };
    // with this we can get all the gateway info for dropdown 
    const getAllMerchant = async () => {
        let body = {
            emailId: adminEmail || '',
            apiKey: adminApiKey || '',
        }
        try {
            const response = await fetch(`${apiUrl}/admin/allMerchants`, {
                method: 'POST',
                headers: {
                    //   'Authorization': 'Bearer YOUR_ACCESS_TOKEN',
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify(body)
            })

            const res = await response.json()
            if (res.responseCode !== 200) return;
            setMerchants(res?.responseData)
        } catch (err) {
            console.log("Error Fetching getAllMerchant: ", err)
        }
    }
    const handleSubmit = async (values, { setSubmitting }) => {
        try {
            const newValue = {
                ...values,
                merchant_id: values.merchant_id.map(merchant => merchant._id)
            }
            if (values.emailId === '' || values.apiKey === '') {
                toast.error("Invalid credential! Please Sign in again..", {
                    position: "top-right",
                    autoClose: 2000, // Auto close the toast after 3 seconds
                    hideProgressBar: false,
                });
                localStorage.removeItem('admin_token');

                // Navigate to the sign-in page
                navigate('/authentication/sign-in');
                return;
            }
            const response = await fetch(`${apiUrl}/admin/editSales`, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify(newValue)
            });
            const res = await response.json();
            if (res.responseCode !== 200) {
                toast.error(res.responseMessage, {
                    position: "top-right",
                    autoClose: 2000, // Auto close the toast after 3 seconds
                    hideProgressBar: false,
                });
                return;
            }

            setSubmitting(false);
            toast.success(res.responseMessage, {
                position: "top-right",
                autoClose: 2000, // Auto close the toast after 3 seconds
                hideProgressBar: false,
            });
            handleCancelClick()
        } catch (error) {
            // Display an error toast
            toast.error('An error occurred. Please try again later.', {
                position: "top-right",
                autoClose: 2000,
                hideProgressBar: false,
            });

            // Reset submitting state
            setSubmitting(false);
        }
    };
    const handleCancelClick = () => {
        // Go back to the previous page
        navigate(-1);
    };

    useEffect(() => {
        getAllMerchant()
    }, [sandbox])
    return (
        <DashboardLayout>
            <DashboardNavbar />
            <Container component="main" maxWidth="sm" sx={{ mb: 4 }}>
                <Paper variant="outlined" sx={{ my: { xs: 3, md: 6 }, p: { xs: 2, md: 3 }, backgroundColor: darkMode ? '#344767' : 'white' }}>
                    <MDTypography variant="h6" gutterBottom mb={4}>
                        Edit Sales Agent
                    </MDTypography>
                    <Formik
                        initialValues={initialValues}
                        validationSchema={validationSchema}
                        onSubmit={handleSubmit}
                    >
                        {({ isSubmitting }) => (
                            <Form>
                                <Grid item lg={12} xl={12} sm={12} xs={12}>
                                    <MDBox mb={2}>
                                        <Field type="text" label="FullName" as={MDInput} fullWidth name="username" />
                                        <MDTypography color="error" variant="caption" >
                                            <ErrorMessage name="username" component="div" />
                                        </MDTypography>
                                    </MDBox>
                                </Grid>

                                <Grid item lg={12} xl={12} sm={12} xs={12}>
                                    <MDBox mb={2}>
                                        <Field
                                            name="merchant_id"
                                            component={({ field, form }) => (
                                                <FormControl fullWidth>
                                                    <Autocomplete
                                                        multiple
                                                        id="merchant-selector"
                                                        options={merchants}
                                                        getOptionLabel={(option) => option.business_name}
                                                        disableCloseOnSelect
                                                        value={field.value}
                                                        onChange={(event, newValue) => form.setFieldValue(field.name, newValue)}
                                                        renderInput={(params) => (
                                                            <TextField
                                                                {...params}
                                                                variant="outlined"
                                                                label="Asign Merchants"
                                                                size="large"
                                                                InputProps={{
                                                                    ...params.InputProps,
                                                                    classes: { root: darkMode ? "select-input-styles-darkMode" : "select-input-styles" },
                                                                }}
                                                            />
                                                        )}
                                                    />
                                                    <FormHelperText error><ErrorMessage name="merchant_id" /></FormHelperText>
                                                </FormControl>
                                            )}
                                        />
                                    </MDBox>
                                </Grid>
                                <MDBox mt={4} mb={1} justifyContent="right">
                                    <Grid container justifyContent="right">
                                        <Grid item display={'flex'}>
                                            <MDBox>
                                                <MDButton
                                                    variant="gradient"
                                                    color="info"
                                                    type="submit"
                                                    disabled={isSubmitting}
                                                >
                                                    {isSubmitting ? 'Saving...' : 'Save'}
                                                </MDButton>
                                            </MDBox>
                                            <MDBox ml={2}>
                                                <MDButton
                                                    variant="gradient"
                                                    color="error"
                                                    onClick={handleCancelClick}
                                                >
                                                    Cancel
                                                </MDButton>
                                            </MDBox>
                                        </Grid>
                                    </Grid>
                                </MDBox>

                            </Form>
                        )}
                    </Formik>
                </Paper>
            </Container>

            {/* <Footer /> */}
        </DashboardLayout>
    );
}

export default editSales;
