/**
=========================================================
* Material Dashboard 2 React - v2.2.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-dashboard-react
* Copyright 2023 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/


import { Formik, Form, Field, ErrorMessage } from 'formik';
import * as Yup from 'yup';
import { toast } from 'react-toastify';
import { useNavigate } from 'react-router-dom';
// Material Dashboard 2 React components

import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import MDButton from "components/MDButton";
import MDInput from 'components/MDInput';
import Card from "@mui/material/Card";

// Material Dashboard 2 React example components
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
// import Footer from "examples/Footer";
import { Grid } from '@mui/material';
import { useConfig } from '../../../config';
function addBalance() {
    const { apiUrl } = useConfig();
    const adminEmail = localStorage.getItem('admin_email')
    const adminApiKey = localStorage.getItem('admin_apiKey')
    const validationSchema = Yup.object({
        emailId: Yup.string().required('Merchant ID is required'),
        balance: Yup.number().required('Balance is required'),
    });
    const validationPayoutSchema = Yup.object({
        email_Id: Yup.string().required('Merchant ID is required'),
        payoutBalance: Yup.number().required('Balance is required'),
    });
    const initialValues = {
        email_Id: adminEmail || '',
        apiKey: adminApiKey || '',
        emailId: '',
        balance: '',
    };
    const initialpayoutValues = {
        emailId: adminEmail || '',
        apiKey: adminApiKey || '',
        email_Id: '',
        payoutBalance: '',
    };

    const navigate = useNavigate();
    const handleSubmit = async (values, { setSubmitting, resetForm }) => {
        try {
            if (values.emailId === '' || values.apiKey === '') {
                toast.error("Invalid credential! Please Sign in again..", {
                    position: "top-right",
                    autoClose: 2000, // Auto close the toast after 3 seconds
                    hideProgressBar: false,
                });
                localStorage.removeItem('admin_token');

                // Navigate to the sign-in page
                navigate('/authentication/sign-in');
                return;
            }
            const response = await fetch(`${apiUrl}/admin/updateuserprofile`, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify(values)
            });
            const res = await response.json();
            if (res.responseCode !== 200) {
                toast.error(res.responseMessage, {
                    position: "top-right",
                    autoClose: 2000, // Auto close the toast after 3 seconds
                    hideProgressBar: false,
                });
                return;
            }

            setSubmitting(false);
            resetForm()
            toast.success(res.responseMessage, {
                position: "top-right",
                autoClose: 2000, // Auto close the toast after 3 seconds
                hideProgressBar: false,
            });
        } catch (error) {
            // Display an error toast
            toast.error('An error occurred. Please try again later.', {
                position: "top-right",
                autoClose: 2000,
                hideProgressBar: false,
            });

            // Reset submitting state
            setSubmitting(false);
        }
    };
    const handlePayoutSubmit = async (values, { setSubmitting, resetForm }) => {
        try {
            if (values.emailId === '' || values.apiKey === '') {
                toast.error("Invalid credential! Please Sign in again..", {
                    position: "top-right",
                    autoClose: 2000, // Auto close the toast after 3 seconds
                    hideProgressBar: false,
                });
                localStorage.removeItem('admin_token');

                // Navigate to the sign-in page
                navigate('/authentication/sign-in');
                return;
            }
            const response = await fetch(`${apiUrl}/admin/updatePayoutBalance`, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify(values)
            });
            const res = await response.json();
            if (res.responseCode !== 200) {
                toast.error(res.responseMessage, {
                    position: "top-right",
                    autoClose: 2000, // Auto close the toast after 3 seconds
                    hideProgressBar: false,
                });
                return;
            }

            setSubmitting(false);
            resetForm()
            toast.success(res.responseMessage, {
                position: "top-right",
                autoClose: 2000, // Auto close the toast after 3 seconds
                hideProgressBar: false,
            });
        } catch (error) {
            // Display an error toast
            toast.error('An error occurred. Please try again later.', {
                position: "top-right",
                autoClose: 2000,
                hideProgressBar: false,
            });

            // Reset submitting state
            setSubmitting(false);
        }
    };



    return (
        <DashboardLayout>
            <DashboardNavbar />
            <MDBox pt={6} pb={3}>
                <Grid container spacing={2}>
                    <Grid item xl={6} lg={6} md={12} sm={12} xs={12}>
                        <Card>
                            <MDBox
                                variant="gradient"
                                bgColor="info"
                                borderRadius="lg"
                                coloredShadow="info"
                                mx={2}
                                mt={5}
                                p={2}
                                mb={1}
                                textAlign="center"
                            >
                                <MDTypography variant="h4" fontWeight="medium" color="white" mt={1}>
                                    Payins
                                </MDTypography>
                            </MDBox>
                            <MDBox pt={4} pb={3} px={3}>
                                <Formik
                                    initialValues={initialValues}
                                    validationSchema={validationSchema}
                                    onSubmit={handleSubmit}
                                >
                                    {({ isSubmitting, resetForm }) => (
                                        <Form>
                                            <MDBox mb={2}>
                                                <Field type="text" label="Merchant ID" as={MDInput} fullWidth name="emailId" />
                                                <MDTypography color="error" variant="caption" >
                                                    <ErrorMessage name="emailId" component="div" />
                                                </MDTypography>
                                            </MDBox>
                                            <MDBox mb={2}>
                                                <Field type="number" label="Balance" as={MDInput} fullWidth name="balance" />
                                                <MDTypography color="error" variant="caption" >
                                                    <ErrorMessage name="balance" component="div" />
                                                </MDTypography>
                                            </MDBox>


                                            <MDBox mt={4} mb={1}>
                                                <Grid container justifyContent="center">
                                                    <Grid item>
                                                        <MDButton
                                                            variant="gradient"
                                                            color="info"
                                                            type="submit"
                                                            disabled={isSubmitting}
                                                        >
                                                            {isSubmitting ? 'Adding Balance...' : 'Add Balance'}
                                                        </MDButton>
                                                    </Grid>
                                                </Grid>
                                            </MDBox>
                                        </Form>
                                    )}
                                </Formik>
                            </MDBox>
                        </Card>
                    </Grid>
                    <Grid item xl={6} lg={6} md={12} sm={12} xs={12}>
                        <Card>
                            <MDBox
                                variant="gradient"
                                bgColor="info"
                                borderRadius="lg"
                                coloredShadow="info"
                                mx={2}
                                mt={5}
                                p={2}
                                mb={1}
                                textAlign="center"
                            >
                                <MDTypography variant="h4" fontWeight="medium" color="white" mt={1}>
                                    Payout
                                </MDTypography>
                            </MDBox>
                            <MDBox pt={4} pb={3} px={3}>
                                <Formik
                                    initialValues={initialpayoutValues}
                                    validationSchema={validationPayoutSchema}
                                    onSubmit={handlePayoutSubmit}
                                >
                                    {({ isSubmitting, resetForm }) => (
                                        <Form>
                                            <MDBox mb={2}>
                                                <Field type="text" label="Merchant ID" as={MDInput} fullWidth name="email_Id" />
                                                <MDTypography color="error" variant="caption" >
                                                    <ErrorMessage name="email_Id" component="div" />
                                                </MDTypography>
                                            </MDBox>
                                            <MDBox mb={2}>
                                                <Field type="number" label="Balance" as={MDInput} fullWidth name="payoutBalance" />
                                                <MDTypography color="error" variant="caption" >
                                                    <ErrorMessage name="payoutBalance" component="div" />
                                                </MDTypography>
                                            </MDBox>


                                            <MDBox mt={4} mb={1}>
                                                <Grid container justifyContent="center">
                                                    <Grid item>
                                                        <MDButton
                                                            variant="gradient"
                                                            color="info"
                                                            type="submit"
                                                            disabled={isSubmitting}
                                                        >
                                                            {isSubmitting ? 'Adding Balance...' : 'Add Balance'}
                                                        </MDButton>
                                                    </Grid>
                                                </Grid>
                                            </MDBox>
                                        </Form>
                                    )}
                                </Formik>
                            </MDBox>
                        </Card>
                    </Grid>
                </Grid>

            </MDBox>
            {/* <Footer /> */}
        </DashboardLayout>
    );
}

export default addBalance;
