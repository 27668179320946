/**
=========================================================
* Material Dashboard 2 React - v2.2.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-dashboard-react
* Copyright 2023 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/


import { Formik, Form, Field, ErrorMessage } from 'formik';
import * as Yup from 'yup';
import { toast } from 'react-toastify';
// import { useNavigate } from 'react-router-dom';
// Material Dashboard 2 React components
import Container from '@mui/material/Container';
import Paper from '@mui/material/Paper';
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import MDButton from "components/MDButton";
import MDInput from 'components/MDInput';
import Card from "@mui/material/Card";
import { MenuItem } from '@mui/material';
// Material Dashboard 2 React example components
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
// import Footer from "examples/Footer";
import { Grid } from '@mui/material';
import { useEffect, useState } from 'react';
import { useConfig } from "../../../config"
import { Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, Button } from '@mui/material';
// import { useUser } from "ZustandState/useUser";
import { useMaterialUIController } from 'context';
import { capitalizeFirstLetter } from 'util/formatDateAndTime';
import { useSandbox } from 'ZustandState/useSanbox';
import { Autocomplete, TextField, FormControl, FormHelperText } from '@mui/material';
import { useNavigate } from 'react-router-dom';
// Data
function addSales() {
    const sandbox = useSandbox((state) => state.sandbox)
    const navigate = useNavigate()
    const [controller] = useMaterialUIController()
    const { darkMode } = controller
    const adminEmail = localStorage.getItem('admin_email')
    const adminApiKey = localStorage.getItem('admin_apiKey')
    // const user = useUser((state) => state.user)
    const [openDialog, setOpenDialog] = useState(false);
    const [responseData, setResponseData] = useState(null);
    const { apiUrl, testUrl } = useConfig()
    const [merchants, setMerchants] = useState([])
    const validationSchema = Yup.object({
        email_Id: Yup.string().required('Email is required'),
        username: Yup.string(),
        password: Yup.string().required('Password is required')
    });

    const initialValues = {
        emailId: adminEmail || '',
        apiKey: adminApiKey || '',
        username: "",
        email_Id: '',
        password: '',
        merchant_id: []
    };
    // with this we can get all the gateway info for dropdown 
    const getAllMerchant = async () => {
        let body = {
            emailId: adminEmail || '',
            apiKey: adminApiKey || '',
        }
        try {
            const response = await fetch(`${apiUrl}/admin/allMerchants`, {
                method: 'POST',
                headers: {
                    //   'Authorization': 'Bearer YOUR_ACCESS_TOKEN',
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify(body)
            })

            const res = await response.json()
            if (res.responseCode !== 200) return;
            setMerchants(res?.responseData)
        } catch (err) {
            console.log("Error Fetching getAllMerchant: ", err)
        }
    }
    const handleSubmit = async (values, { setSubmitting }) => {
        try {
            const newValue = {
                ...values,
                merchant_id: values.merchant_id.map(merchant => merchant._id)
            }
            if (values.emailId === '' || values.apiKey === '') {
                toast.error("Invalid credential! Please Sign in again..", {
                    position: "top-right",
                    autoClose: 2000, // Auto close the toast after 3 seconds
                    hideProgressBar: false,
                });
                localStorage.removeItem('admin_token');

                // Navigate to the sign-in page
                navigate('/authentication/sign-in');
                return;
            }
            const response = await fetch(`${apiUrl}/admin/addSales`, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify(newValue)
            });
            const res = await response.json();
            if (res.responseCode !== 200) {
                toast.error(res.responseMessage, {
                    position: "top-right",
                    autoClose: 2000, // Auto close the toast after 3 seconds
                    hideProgressBar: false,
                });
                return;
            }

            setSubmitting(false);
            const userBody = {
                emailId: res.responseData.emailId,
                password: res.responseData.password,
                apiKey: res.responseData.apiKey
            }
            setResponseData(userBody);
            setOpenDialog(true);
            toast.success(res.responseMessage, {
                position: "top-right",
                autoClose: 2000, // Auto close the toast after 3 seconds
                hideProgressBar: false,
            });
            // navigate(-1)
        } catch (error) {
            // Display an error toast
            toast.error('An error occurred. Please try again later.', {
                position: "top-right",
                autoClose: 2000,
                hideProgressBar: false,
            });

            // Reset submitting state
            setSubmitting(false);
        }
    };
    const handleCloseDialog = () => {
        setOpenDialog(false);
    };
    const handleCancelClick = () => {
        // Go back to the previous page
        navigate(-1);
    };

    const handleCopyToClipboard = () => {
        const dataToCopy = `Address: sales.payhub.link\nEmail id: ${responseData.emailId}\nPassword: ${responseData.password}`;
        navigator.clipboard.writeText(dataToCopy);
        toast.success('Sales data copied to clipboard', {
            position: "top-right",
            autoClose: 2000,
            hideProgressBar: false,
        });
    };
    useEffect(() => {
        getAllMerchant()
    }, [sandbox])
    return (
        <DashboardLayout>
            <DashboardNavbar />
            {/* <MDBox pt={6} pb={3}>
                <Card>
                    <MDBox
                        variant="gradient"
                        bgColor="info"
                        borderRadius="lg"
                        coloredShadow="info"
                        mx={2}
                        mt={5}
                        p={2}
                        mb={1}
                        textAlign="center"
                    >
                        <MDTypography variant="h4" fontWeight="medium" color="white" mt={1}>
                            Add Sales Agent
                        </MDTypography>
                    </MDBox>
                    <MDBox pt={4} pb={3} px={3}>
                        <Formik
                            initialValues={initialValues}
                            validationSchema={validationSchema}
                            onSubmit={handleSubmit}
                        >
                            {({ isSubmitting }) => (
                                <Form>
                                    <MDBox mb={2}>
                                        <Field type="text" label="Full Name" as={MDInput} fullWidth name="username" />
                                        <MDTypography color="error" variant="caption" >
                                            <ErrorMessage name="username" component="div" />
                                        </MDTypography>
                                    </MDBox>
                                    <MDBox mb={2}>
                                        <Field type="text" label="Email Id" as={MDInput} fullWidth name="email_Id" />
                                        <MDTypography color="error" variant="caption" >
                                            <ErrorMessage name="email_Id" component="div" />
                                        </MDTypography>
                                    </MDBox>

                                    <MDBox mb={2}>
                                        <Field type="text" label="Password" as={MDInput} fullWidth name="password" />
                                        <MDTypography color="error" variant="caption" >
                                            <ErrorMessage name="password" component="div" />
                                        </MDTypography>
                                    </MDBox>
                                    <MDBox mb={2}>
                                        <Field
                                            name="merchant_id"
                                            component={({ field, form }) => (
                                                <FormControl fullWidth>
                                                    <Autocomplete
                                                        multiple
                                                        id="merchant-selector"
                                                        options={merchants}
                                                        getOptionLabel={(option) => option.business_name}
                                                        disableCloseOnSelect
                                                        value={field.value}
                                                        onChange={(event, newValue) => form.setFieldValue(field.name, newValue)}
                                                        renderInput={(params) => (
                                                            <TextField
                                                                {...params}
                                                                variant="outlined"
                                                                label="Asign Merchants"
                                                                size="large"
                                                                InputProps={{
                                                                    ...params.InputProps,
                                                                    classes: { root: darkMode ? "select-input-styles-darkMode" : "select-input-styles" },
                                                                }}
                                                            />
                                                        )}
                                                    />
                                                    <FormHelperText error><ErrorMessage name="merchant_id" /></FormHelperText>
                                                </FormControl>
                                            )}
                                        />

                                    </MDBox>

                                    <MDBox mt={4} mb={1}>
                                        <Grid container justifyContent="center">
                                            <Grid item>
                                                <MDButton
                                                    variant="gradient"
                                                    color="info"
                                                    type="submit"
                                                    disabled={isSubmitting}
                                                >
                                                    {isSubmitting ? 'Adding Sales Agent...' : 'Add Sales Agent'}
                                                </MDButton>
                                            </Grid>
                                        </Grid>
                                    </MDBox>

                                </Form>
                            )}
                        </Formik>
                    </MDBox>
                </Card>
            </MDBox> */}
            <Container component="main" maxWidth="sm" sx={{ mb: 4 }}>
                <Paper variant="outlined" sx={{ my: { xs: 3, md: 6 }, p: { xs: 2, md: 3 }, backgroundColor: darkMode ? '#344767' : 'white' }}>
                    <MDTypography variant="h6" gutterBottom mb={4}>
                        Create Sales Agent
                    </MDTypography>
                    <Formik
                        initialValues={initialValues}
                        validationSchema={validationSchema}
                        onSubmit={handleSubmit}
                    >
                        {({ isSubmitting }) => (
                            <Form>
                                <Grid container spacing={3}>
                                    <Grid item xs={12} sm={6}>
                                        <MDBox mb={2}>
                                            <Field type="text" label="Email" as={MDInput} fullWidth name="email_Id" />
                                            <MDTypography color="error" variant="caption" >
                                                <ErrorMessage name="email_Id" component="div" />
                                            </MDTypography>
                                        </MDBox>
                                    </Grid>
                                    <Grid item xs={12} sm={6}>
                                        <MDBox mb={2}>
                                            <Field type="text" label="Password" as={MDInput} fullWidth name="password" />
                                            <MDTypography color="error" variant="caption" >
                                                <ErrorMessage name="password" component="div" />
                                            </MDTypography>
                                        </MDBox>
                                    </Grid>
                                </Grid>
                                <Grid item lg={12} xl={12} sm={12} xs={12}>
                                    <MDBox mb={2}>
                                        <Field type="text" label="FullName" as={MDInput} fullWidth name="username" />
                                        <MDTypography color="error" variant="caption" >
                                            <ErrorMessage name="username" component="div" />
                                        </MDTypography>
                                    </MDBox>
                                </Grid>

                                <Grid item lg={12} xl={12} sm={12} xs={12}>
                                    <MDBox mb={2}>
                                        <Field
                                            name="merchant_id"
                                            component={({ field, form }) => (
                                                <FormControl fullWidth>
                                                    <Autocomplete
                                                        multiple
                                                        id="merchant-selector"
                                                        options={merchants}
                                                        getOptionLabel={(option) => option.business_name}
                                                        disableCloseOnSelect
                                                        value={field.value}
                                                        onChange={(event, newValue) => form.setFieldValue(field.name, newValue)}
                                                        renderInput={(params) => (
                                                            <TextField
                                                                {...params}
                                                                variant="outlined"
                                                                label="Asign Merchants"
                                                                size="large"
                                                                InputProps={{
                                                                    ...params.InputProps,
                                                                    classes: { root: darkMode ? "select-input-styles-darkMode" : "select-input-styles" },
                                                                }}
                                                            />
                                                        )}
                                                    />
                                                    <FormHelperText error><ErrorMessage name="merchant_id" /></FormHelperText>
                                                </FormControl>
                                            )}
                                        />
                                    </MDBox>
                                </Grid>
                                <MDBox mt={4} mb={1} justifyContent="right">
                                    <Grid container justifyContent="right">
                                        <Grid item display={'flex'}>
                                            <MDBox>
                                                <MDButton
                                                    variant="gradient"
                                                    color="info"
                                                    type="submit"
                                                    disabled={isSubmitting}
                                                >
                                                    {isSubmitting ? 'Creating...' : 'Create'}
                                                </MDButton>
                                            </MDBox>
                                            <MDBox ml={2}>
                                                <MDButton
                                                    variant="gradient"
                                                    color="error"
                                                    onClick={handleCancelClick}
                                                >
                                                    Cancel
                                                </MDButton>
                                            </MDBox>
                                        </Grid>
                                    </Grid>
                                </MDBox>

                            </Form>
                        )}
                    </Formik>
                </Paper>
            </Container>
            {/* Response Dialog */}
            <Dialog open={openDialog} onClose={handleCloseDialog}>
                <DialogTitle>Merchant Data</DialogTitle>
                <DialogContent>

                    <div>
                        <div>Address: sales.payhub.link</div>
                        <div>Email id: {responseData?.emailId}</div>
                        <div>Password: {responseData?.password}</div>
                    </div>
                </DialogContent>
                <DialogActions>
                    <Button onClick={handleCopyToClipboard} color="primary">
                        Copy to Clipboard
                    </Button>
                    <Button onClick={handleCloseDialog} color="primary">
                        Close
                    </Button>
                </DialogActions>
            </Dialog>
            {/* <Footer /> */}
        </DashboardLayout>
    );
}

export default addSales;
