
import PropTypes from 'prop-types';
import { useMaterialUIController } from 'context';
import DataTable from './DataTable';
import dataFormat from "layouts/merchantTopup/merchantOverview/data";
function merchantOverview(props) {
    const [controller] = useMaterialUIController()
    const { list } = props
    const { darkMode } = controller
    const { columns, rows } = dataFormat(list);
    return (
        <DataTable
            table={{ columns, rows }}
            showTotalEntries={false}
            isSorted={false}
            noEndBorder
            entriesPerPage={false}
        />
    );
}

merchantOverview.propTypes = {
    list: PropTypes.array
};

export default merchantOverview;
