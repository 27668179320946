/**
=========================================================
* Material Dashboard 2 React - v2.2.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-dashboard-react
* Copyright 2023 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

// @mui material components
import Card from "@mui/material/Card";

// Material Dashboard 2 React components
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import MDButton from "components/MDButton"
// Billing page components
import Sales from "layouts/sales/allSales/sales";
import PropTypes from "prop-types";
import { Icon } from "@mui/material";
import { Link } from "react-router-dom";
function SalesInformation({ allSales, getAllSalesFunc }) {
    return (
        <Card id="delete-account">
            <MDBox pt={3} px={2} display="flex" flexDirection="row" justifyContent="flex-end" alignItems="center">
                <Link to={'/add-sales'}>
                    <MDButton color={'info'}>
                        <Icon sx={{ marginRight: "1vh" }}>
                            group_add
                        </Icon>
                        Create Sales Agent
                    </MDButton>
                </Link>
            </MDBox>

            <MDBox pt={1} pb={2} px={2}>
                <MDBox component="ul" display="flex" flexDirection="column" p={0} m={0}>
                    {
                        allSales && allSales.map((item, index) => (
                            <Sales
                                key={index}
                                email={item?.emailId || ''}
                                name={item?.username || ''}
                                merchants={item?.merchants || []}
                                getAllSalesFunc={getAllSalesFunc}
                            />
                        ))
                    }
                </MDBox>
            </MDBox>
        </Card>
    );
}
SalesInformation.propTypes = {
    allSales: PropTypes.array,
    getAllSalesFunc: PropTypes.func
};
export default SalesInformation;
