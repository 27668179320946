/* eslint-disable react/prop-types */
/* eslint-disable react/function-component-definition */
/**
=========================================================
* Material Dashboard 2 React - v2.2.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-dashboard-react
* Copyright 2023 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

// Material Dashboard 2 React components
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
// import MDAvatar from "components/MDAvatar";
import MDBadge from "components/MDBadge";
import formatDateAndTime from "util/formatDateAndTime";

// Images
// import team2 from "assets/images/team-2.jpg";
// import team3 from "assets/images/team-3.jpg";
// import team4 from "assets/images/team-4.jpg";
export default function data(data) {

  return {
    columns: [
      { Header: "time", accessor: "time", align: "center" },
      { Header: "date", accessor: "date", align: "center" },
      { Header: "amount", accessor: "amount", align: "left" },
      { Header: "status", accessor: "status", align: "center" },
      { Header: "utr", accessor: "utr", },
      { Header: "transaction id", accessor: "transactionid", },
      //{ Header: "currency", accessor: "currency", align: "left" },


    ],

    rows: data && data.reverse().map((item, index) => ({
      time: (
        <MDTypography href="#" variant="caption" color="text" fontWeight="medium">
          {formatDateAndTime(item.transaction_date).formattedTime}
        </MDTypography>
      ),
      date: (
        <MDTypography href="#" variant="caption" color="text" fontWeight="medium">
          {formatDateAndTime(item.transaction_date).formattedDate}
        </MDTypography>
      ),
      amount: (
        <MDTypography href="#" variant="caption" color="text" fontWeight="medium">
          {item.amount}
        </MDTypography>
      ),
      status: (
        <MDBox ml={-1}>
          <MDBadge badgeContent={item.status} color={item.status === "Success" || item.status === "success" ? "success" : item.status === "IN-PROCESS" ? "warning" : item.status === "PENDING" ? "warning" : "error"} variant="gradient" size="sm" />
        </MDBox>
      ),
      utr: (
        <MDTypography href="#" variant="caption" color="text" fontWeight="medium">
          {item?.utr || ''}
        </MDTypography>
      ),
      transactionid: (
        <MDTypography href="#" variant="caption" color="text" fontWeight="medium">
          {item.transactionId}
        </MDTypography>
      ),
      // currency: (
      //   <MDTypography component="a" href="#" variant="caption" color="text" fontWeight="medium">
      //     {item.currency}
      //   </MDTypography>
      // ),




    }))
  };


}
