import { Grid } from '@mui/material'
import DashboardLayout from 'examples/LayoutContainers/DashboardLayout'
import DashboardNavbar from 'examples/Navbars/DashboardNavbar'
import MDBox from 'components/MDBox'
import { useEffect, useState } from 'react'
import ComplexStatisticsCard from 'examples/Cards/StatisticsCards/ComplexStatisticsCard'
import DefaultProjectCard from 'examples/Cards/ProjectCards/DefaultProjectCard'
import SettlementLogs from "layouts/merchantSettlements/settlementLogs"
import MDTypography from 'components/MDTypography'
import { useMaterialUIController } from 'context'
import { Dialog, DialogContent, DialogTitle } from '@mui/material';
import { Formik, Form, Field, ErrorMessage } from 'formik';
import MDInput from 'components/MDInput'
import MDButton from 'components/MDButton'
import { useConfig } from "../../../config"
import formatDateAndTime from "util/formatDateAndTime";
import * as Yup from 'yup';
import { toast } from 'react-toastify';
import { useLocation } from 'react-router-dom'
import FileDownloadOutlinedIcon from '@mui/icons-material/FileDownloadOutlined';
import BeatLoader from "react-spinners/BeatLoader";
import { PDFDocument, rgb } from 'pdf-lib';
import logo from "assets/images/payhub.png"
import { capitalizeFirstLetter } from 'util/formatDateAndTime'
import { useNavigate } from 'react-router-dom'
import Loader from 'util/Loader'
import { useSandbox } from 'ZustandState/useSanbox'
function settleMerchant() {
    const navigate = useNavigate()
    const { state } = useLocation()
    const { apiUrl } = useConfig()
    const sandbox = useSandbox((state) => state.sandbox)
    const adminEmail = localStorage.getItem('admin_email');
    const adminApiKey = localStorage.getItem('admin_apiKey');
    const limit = 10
    const [page, setPage] = useState(0)
    const [csvCompleteData, setCsvCompleteData] = useState([])
    const [loading, setLoading] = useState(false)
    const [allDataLoading, setAllDataLoading] = useState(true)
    const validationSchema = Yup.object({
        amount: Yup.number().required('Gross Volume is required'),
        feesPercentage: Yup.number().min(0).max(100).required('Fees Percentage is required'),
        usdtRate: Yup.number(),
        ref_no: Yup.string(),
    });
    const [settlementLogs, setSettlementLogs] = useState([])
    const [controller] = useMaterialUIController()
    const {
        darkMode
    } = controller
    const [data, setData] = useState()
    const [totalData, setTotalData] = useState()
    const initialValues = {
        emailId: adminEmail || '',
        apiKey: adminApiKey || '',
        email_Id: state || '',
        amount: '',
        feesPercentage: '',
        usdtRate: '',
        ref_no: '',
        // notes: ''
    }
    const [netVolume, setNetVolume] = useState(0)
    const [netFees, setNetFees] = useState(0)
    const [usdtNet, setUsdtNet] = useState(0)
    const [openDialog, setOpenDialog] = useState(false);
    const handleCloseDialog = () => {
        setNetFees(0)
        setNetVolume(0)
        setUsdtNet(0)
        setOpenDialog(false);

    };
    const downloadPdfInvoice = async (log) => {
        const pdfDoc = await PDFDocument.create();
        // Set page size to A4
        const page = pdfDoc.addPage([595, 842]); // 595 x 842 are the dimensions for A4 in points
        // Add left border Line
        page.drawLine({ start: { x: 8, y: 830 }, end: { x: 8, y: 12 }, thickness: 1, color: rgb(0, 0, 0) });
        // Add right border Line
        page.drawLine({ start: { x: 587, y: 830 }, end: { x: 587, y: 12 }, thickness: 1, color: rgb(0, 0, 0) });
        // Add upper border Line
        page.drawLine({ start: { x: 8, y: 830 }, end: { x: 587, y: 830 }, thickness: 1, color: rgb(0, 0, 0) });
        // Add bottom border Line
        page.drawLine({ start: { x: 8, y: 12 }, end: { x: 587, y: 12 }, thickness: 1, color: rgb(0, 0, 0) });

        // Add Company Logo
        const logoUrl = logo; // Replace with the actual path or URL to your logo
        const logoImageBytes = await fetch(logoUrl).then(res => res.arrayBuffer());
        const logoImage = await pdfDoc.embedPng(logoImageBytes);
        page.drawImage(logoImage, { x: 380, y: 770, width: 200, height: 40 });

        // Add Merchant Information
        const merchantInfo = [
            { title: 'Merchant Name', value: capitalizeFirstLetter(data?.business_name) },
            { title: 'Settlement Date', value: log.transaction_date.split('T')[0] || '' },
            { title: 'Settlement No.', value: (log.txIndex + 1).toString() },
        ];

        merchantInfo.forEach(({ title, value }, index) => {
            page.drawText(`${title}:`, { x: 50, y: 700 - index * 30, size: 18, color: rgb(0, 0, 0) });
            page.drawText(value, { x: 350, y: 700 - index * 30, size: 18, color: rgb(0, 0, 0) });
        });

        // Add Settlement Report
        page.drawRectangle({ x: 50, y: 580, width: 500, height: 30, color: rgb(0, 0, 0) });
        page.drawText('Settlement Report', { x: 200, y: 587, fontSize: 11, color: rgb(1, 1, 1) });

        const feeCharged = log?.feeCharged;
        const feeChargedValue = feeCharged !== undefined ? feeCharged.toFixed(0).toString() : '';

        // Add Data Section
        const dataSection = [
            { title: 'Merchant Volume', value: (log?.amount && log.amount.toFixed(0) || '').toString() },
            { title: 'Gateway Fees', value: (log?.feePercentage && log?.feePercentage + '%' || '').toString() },
            { title: 'Net Gateway Fees', value: feeChargedValue },
        ];

        dataSection.forEach(({ title, value }, index) => {
            page.drawText(title, { x: 50, y: 530 - index * 35, size: 15, color: rgb(0, 0, 0) });
            page.drawText(value, { x: 400, y: 530 - index * 35, size: 15, color: rgb(0, 0, 0) });
        });

        // Add Footer Line
        page.drawLine({ start: { x: 50, y: 180 }, end: { x: 550, y: 180 }, thickness: 1, color: rgb(0, 0, 0) });

        // Add Footer Data
        const footerData = [
            { title: 'Merchant Net', value: (log?.amountSettled && log?.amountSettled.toFixed(0) || '').toString() },
            { title: 'USDT Rate', value: (log?.usdtRate).toString() || '' },
            { title: 'USDT Net Settlement', value: (log?.usdt?.toFixed(0)).toString() },
        ];

        footerData.forEach(({ title, value }, index) => {
            page.drawText(title, { x: 50, y: 150 - index * 30, size: 15, color: rgb(0, 0, 0) });
            page.drawText(value, { x: 400, y: 150 - index * 30, size: 15, color: rgb(0, 0, 0) });
        });

        let filename = `invoice,${capitalizeFirstLetter(data?.business_name)} ${formatDateAndTime(log.transaction_date).formattedDate}.pdf`

        // Save the PDF
        const pdfBytes = await pdfDoc.save();

        // Trigger download
        const blob = new Blob([pdfBytes], { type: 'application/pdf' });
        const link = document.createElement('a');
        link.href = window.URL.createObjectURL(blob);
        link.download = filename;
        link.click();
    }
    const getMerchantData = async () => {
        try {
            let body = {
                emailId: adminEmail || '',
                apiKey: adminApiKey || '',
                email_Id: state || ''
            }
            if (body.emailId === '' || body.apiKey === '') {
                return;
            }
            const response = await fetch(`${apiUrl}/admin/getmerchantdata`, {
                method: 'POST',
                headers: {
                    //   'Authorization': 'Bearer YOUR_ACCESS_TOKEN',
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify(body)
            })

            if (!response) return;
            const res = await response.json()
            if (res.responseCode !== 200) return;
            setData(res?.responseData)
        } catch (err) {
            console.log("Error Fetching Transactions: ", err)
        }
    }
    const getMerchantTotalData = async () => {
        try {
            let body = {
                emailId: adminEmail || '',
                apiKey: adminApiKey || '',
                email_Id: state || ''
            }
            if (body.emailId === '' || body.apiKey === '') {
                return;
            }
            const response = await fetch(`${apiUrl}/admin/gettotalvolumeandsettlements`, {
                method: 'POST',
                headers: {
                    //   'Authorization': 'Bearer YOUR_ACCESS_TOKEN',
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify(body)
            })

            if (!response) return;
            const res = await response.json()
            if (res.responseCode !== 200) return;
            setTotalData(res?.responseData)
        } catch (err) {
            console.log("Error Fetching Transactions: ", err)
        }
    }
    const getSettlementLogs = async () => {
        try {
            let body = {
                emailId: adminEmail || '',
                apiKey: adminApiKey || '',
                email_Id: state || '',
                limit,
                skip: page * limit,
            }
            if (body.emailId === '' || body.apiKey === '') {
                return;
            }
            const response = await fetch(`${apiUrl}/admin/getmerchantsettlements`, {
                method: 'POST',
                headers: {
                    //   'Authorization': 'Bearer YOUR_ACCESS_TOKEN',
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify(body)
            })

            if (!response) return;
            const res = await response.json()
            if (res.responseCode !== 200) return;
            setSettlementLogs(res?.responseData)
        } catch (err) {
            console.log("Error Fetching Transactions: ", err)
        }
    }
    const getSettlementLogsForCsv = async (body) => {
        try {
            if (body.emailId === '' || body.apiKey === '') {
                return;
            }
            const response = await fetch(`${apiUrl}/admin/getmerchantsettlements`, {
                method: 'POST',
                headers: {
                    //   'Authorization': 'Bearer YOUR_ACCESS_TOKEN',
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify(body)
            })

            if (!response) return;
            const res = await response.json()
            if (res.responseCode !== 200) return;
            return res?.responseData
        } catch (err) {
            console.log("Error Fetching Transactions: ", err)
        }
    }

    const handleSettle = async (values, { setSubmitting }) => {
        try {
            if (values.emailId === '' || values.apiKey === '') {
                toast.error("Invalid credential! Please Sign in again..", {
                    position: "top-right",
                    autoClose: 2000, // Auto close the toast after 3 seconds
                    hideProgressBar: false,
                });
                localStorage.removeItem('admin_token');

                // Navigate to the sign-in page
                navigate('/authentication/sign-in');
                return;
            }
            values.netFees = netFees
            values.netVolume = netVolume
            values.netUSDT = usdtNet
            const response = await fetch(`${apiUrl}/admin/settlemoney`, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify(values)
            });
            const res = await response.json();
            if (res.responseCode !== 200) {
                toast.error(res.responseMessage, {
                    position: "top-right",
                    autoClose: 2000, // Auto close the toast after 3 seconds
                    hideProgressBar: false,
                });
                return;
            }

            setSubmitting(false);
            toast.success(res.responseMessage, {
                position: "top-right",
                autoClose: 2000, // Auto close the toast after 3 seconds
                hideProgressBar: false,
            });
            page > 0 ? (setPage(0), getMerchantTotalData(), getMerchantData()) : (getSettlementLogs(), getMerchantTotalData(), getMerchantData())
            handleCloseDialog()
        } catch (error) {
            // Display an error toast
            toast.error('An error occurred. Please try again later.', {
                position: "top-right",
                autoClose: 2000,
                hideProgressBar: false,
            });

            // Reset submitting state
            setSubmitting(false);
        }
    };

    const downloadSettlementLogs = async () => {
        setLoading(true)
        setCsvCompleteData([])
        let csvPage = 0
        let responseData = true
        do {
            let body = {
                emailId: adminEmail || '',
                apiKey: adminApiKey || '',
                email_Id: state || '',
                limit: 500,
                skip: csvPage * 500,
            }

            await getSettlementLogsForCsv(body).then((res) => {
                if (res.length === 0) responseData = false;
                csvCompleteData.push(...res)
            })
            csvPage++
        } while (responseData)

        // Create the CSV content
        const csvContent = [
            ["Time", "Date", "Gross Volume", "Net Fees", "Net Volume", "USDT Rate", "USDT Net", "Ref", "Notes"],
            ...csvCompleteData.map(({ transaction_date, amount, feeCharged, amountSettled, usdtRate, usdt, ref_no, notes }) => [
                formatDateAndTime(transaction_date).formattedTime || '',
                formatDateAndTime(transaction_date).formattedDate || '',
                amount,

                feeCharged,
                amountSettled,
                usdtRate,
                usdt,
                ref_no || '',
                notes || ''
            ]),
        ].map(row => row.join(',')).join('\n');

        // Create a Blob containing the CSV data
        const blob = new Blob([csvContent], { type: 'text/csv' });

        // Create a download link
        const url = URL.createObjectURL(blob);
        const a = document.createElement('a');
        a.href = url;
        a.download = 'settlement.csv';

        // Programmatically click the link to trigger the download
        a.click();

        // Clean up
        URL.revokeObjectURL(url);
        setLoading(false)
    }

    const calculateNetVolume = (grossVolume, fee, usdtRates) => {
        const fees = (fee / 100) * grossVolume; // Calculating fees based on percentage
        const net = grossVolume - fees; // Calculating net volume
        setNetFees(fees)
        setNetVolume(net);
        calculateNetUSDT(usdtRates, net)
    }
    const calculateNetUSDT = (rate, netVolume) => {
        const net = (netVolume !== undefined && netVolume !== '') ? parseFloat(netVolume) : 0;
        const usdtRate = (rate !== undefined && rate !== '') ? parseFloat(rate) : 0;

        if (usdtRate === 0) {
            // Handle division by zero case
            setUsdtNet(0);
        } else {
            const netUSDT = net / usdtRate; // Calculating net USDT based on rate
            setUsdtNet(netUSDT);
        }
    }



    useEffect(() => {
        setAllDataLoading(true)
        getMerchantData()
        getMerchantTotalData().then(() => {
            setAllDataLoading(false)
        })
    }, [state, sandbox])
    useEffect(() => {
        getSettlementLogs()
    }, [state, page, sandbox])

    return (
        <DashboardLayout>
            <DashboardNavbar />
            {
                allDataLoading ? (<Loader />) : (
                    <MDBox py={3}>
                        <MDBox mt={1}>
                            {data && totalData && <Grid display={'flex'} flexDirection={'row'} container spacing={3}>
                                <Grid xl={3}>
                                    <DefaultProjectCard
                                        business={data?.business_name}
                                        email={data?.emailId}
                                        name={`${data?.first_name} ${data?.last_name}`}
                                        btn={setOpenDialog}
                                        type="settle"
                                    />
                                </Grid>
                                <Grid xl={9}>
                                    <Grid container spacing={3}>
                                        <Grid item xs={12} lg={2.4} xl={2.4}>
                                            <MDBox mb={1.5}>
                                                <ComplexStatisticsCard
                                                    color="7FDF9A"
                                                    title="Today's Volume"
                                                    count={Number(data?.last24hr).toFixed(0) || ''}
                                                />
                                            </MDBox>
                                        </Grid>

                                        <Grid item xs={12} lg={2.4} xl={2.4}>
                                            <MDBox mb={1.5}>
                                                <ComplexStatisticsCard
                                                    color="FFFED2"
                                                    title="Yesterday's Volume"
                                                    count={`${Number(data?.yesterday).toFixed(0) || ''}`}
                                                />
                                            </MDBox>
                                        </Grid>
                                        <Grid item xs={12} lg={2.4} xl={2.4}>
                                            <MDBox mb={1.5}>
                                                <ComplexStatisticsCard
                                                    color="D4BEF0"
                                                    title="Total Volume"
                                                    count={`${Number(totalData?.totalVolume).toFixed(0) || ''}`}
                                                />
                                            </MDBox>
                                        </Grid>
                                        <Grid item xs={12} lg={2.4} xl={2.4}>
                                            <MDBox mb={1.5}>
                                                <ComplexStatisticsCard
                                                    color="BBF2EA"
                                                    title="Total Settlement"
                                                    count={`${Number(totalData?.totalSettlements).toFixed(0) || ''}`}
                                                />
                                            </MDBox>
                                        </Grid>
                                        <Grid item xs={12} lg={2.4} xl={2.4}>
                                            <MDBox mb={1.5}>
                                                <ComplexStatisticsCard
                                                    color="C9DEDE"
                                                    title="Wallet Balance"
                                                    count={`${Number(data.balance).toFixed(0) || 0}`}
                                                />
                                            </MDBox>
                                        </Grid>
                                    </Grid>
                                </Grid>
                            </Grid>}
                            {/* settlementLogs */}
                            <MDBox mt={5} mx={0.5} ml={3}>
                                <Grid display={'flex'} flexDirection={'row'} container spacing={3}>
                                    <Grid xl={12}>
                                        <MDBox display={'flex'} flexDirection={'row'}>
                                            <MDTypography variant="h6" color={darkMode ? "white" : "black"}>Settlement Logs</MDTypography>
                                            <MDBox pl={3}>
                                                {
                                                    loading ? (<BeatLoader
                                                        color="#36d7b7"
                                                        cssOverride={{}}
                                                        size={10}
                                                    />) : (
                                                        <FileDownloadOutlinedIcon onClick={() => downloadSettlementLogs()} sx={{ cursor: "pointer", fontWeight: "bold" }} color={darkMode ? "white" : "black"} fontSize="medium" />
                                                    )
                                                }

                                            </MDBox>
                                        </MDBox>
                                    </Grid>

                                    <SettlementLogs pages={page} pageSetter={setPage} logs={settlementLogs} downloadInvoice={downloadPdfInvoice} />

                                </Grid>
                            </MDBox>
                        </MDBox>
                    </MDBox>
                )
            }

            <Dialog PaperProps={{
                style: {
                    minHeight: '35vh',
                    minWidth: '45vw',
                    background: darkMode ? "#344767" : "white",
                    boxShadow: '0px 10px 20px 0px rgba(0,0,0,0.2)', // Shadow effect
                    borderRadius: '10px', // Rounded corners
                    position: 'absolute',
                    left: '50%', // Adjust the left value to move the dialog to the right
                },
            }} open={openDialog} onClose={handleCloseDialog}>
                <DialogTitle>SETTLEMENT</DialogTitle>
                <DialogContent>
                    <MDBox px={1} py={1} display={'flex'} flexDirection={'row'} justifyContent={'flex-end'}>
                        <MDTypography px={2} color="text" fontWeight="medium" fontSize="large" variant="caption" >
                            Net Volume: {netVolume?.toFixed(2)}
                        </MDTypography>
                        <MDTypography px={2} color="text" fontWeight="medium" fontSize="large" variant="caption" >
                            Net Fees: {netFees?.toFixed(2)}
                        </MDTypography>
                        <MDTypography px={2} color="text" fontSize="large" fontWeight="medium" variant="caption" >
                            Usdt Net: {usdtNet?.toFixed(2)}
                        </MDTypography>
                    </MDBox>
                    <Formik
                        initialValues={initialValues}
                        validationSchema={validationSchema}
                        onSubmit={handleSettle}
                    >
                        {({ isSubmitting, values, setFieldValue }) => (
                            <Form>
                                <MDBox mb={2}>
                                    <Field type="number" label="Gross Volume" as={MDInput} fullWidth name="amount"
                                        onChange={(e) => {
                                            setFieldValue('amount', e.target.value)
                                            calculateNetVolume(e.target.value, values.feesPercentage, values.usdtRate)
                                        }}
                                    />
                                    <MDTypography color="error" variant="caption" >
                                        <ErrorMessage name="amount" component="div" />
                                    </MDTypography>
                                </MDBox>
                                <MDBox mb={2}>
                                    <Field type="number" label="Fees %" as={MDInput} fullWidth name="feesPercentage"
                                        onChange={(e) => {
                                            setFieldValue('feesPercentage', e.target.value)
                                            calculateNetVolume(values.amount, e.target.value, values.usdtRate)
                                        }}
                                    />
                                    <MDTypography color="error" variant="caption" >
                                        <ErrorMessage name="feesPercentage" component="div" />
                                    </MDTypography>
                                </MDBox>
                                <MDBox mb={2}>
                                    <Field type="number" label="USDT Rate" as={MDInput} fullWidth name="usdtRate"
                                        onChange={(e) => {
                                            setFieldValue('usdtRate', e.target.value)
                                            calculateNetUSDT(e.target.value, netVolume)
                                        }}
                                    />
                                    <MDTypography color="error" variant="caption" >
                                        <ErrorMessage name="usdtRate" component="div" />
                                    </MDTypography>
                                </MDBox>
                                <MDBox mb={2}>
                                    <Field type="text" label="Ref" as={MDInput} fullWidth name="ref_no" />
                                    <MDTypography color="error" variant="caption" >
                                        <ErrorMessage name="ref_no" component="div" />
                                    </MDTypography>
                                </MDBox>
                                {/* <MDBox mb={2}>
                                    <Field type="text" label="Notes" as={MDInput} fullWidth name="notes" />
                                    <MDTypography color="error" variant="caption" >
                                        <ErrorMessage name="notes" component="div" />
                                    </MDTypography>
                                </MDBox> */}
                                <MDBox display={'flex'} flexDirection={'row'}>
                                    <MDBox>
                                        <MDButton
                                            type="submit" color="success" disabled={isSubmitting}
                                        >
                                            {isSubmitting ? 'Settling...' : 'Settle Merchant'}
                                        </MDButton>
                                    </MDBox>
                                    <MDBox ml={3}>
                                        <MDButton
                                            onClick={handleCloseDialog} color="error"
                                        >
                                            Cancel
                                        </MDButton>
                                    </MDBox>
                                </MDBox>
                            </Form>
                        )}

                    </Formik>
                </DialogContent>

            </Dialog>
        </DashboardLayout>
    )
}

export default settleMerchant