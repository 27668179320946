/* eslint-disable react/prop-types */
/* eslint-disable react/function-component-definition */
/**
=========================================================
* Material Dashboard 2 React - v2.2.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-dashboard-react
* Copyright 2023 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

// @mui material components
import MDTypography from "components/MDTypography";
import MDBox from "components/MDBox";
import MDBadge from "components/MDBadge";
import formatDateAndTime from "util/formatDateAndTime";
import { useMaterialUIController } from "context";
export default function data(transactions) {
    const [controller] = useMaterialUIController()
    const { darkMode } = controller
    return {
        columns: [
            { Header: "Time", accessor: "time", align: "left", width: "10%" },
            { Header: "Date", accessor: "date", align: "left", width: "15%" },
            { Header: "amount", accessor: "amount", align: "right", width: "10%" },
            { Header: "status", accessor: "status", align: "center", width: "10%" },
            { Header: "UTR", accessor: "utr", width: "20%", align: "left" },
            { Header: "TXID", accessor: "txid", width: "20%", align: "left" },
        ],

        rows: transactions && transactions.map((row, index) => ({
            time: (
                <MDTypography variant="caption" color={darkMode ? "white" : "black"} fontWeight="medium">
                    {formatDateAndTime(row?.transaction_date).formattedTime || ''}
                </MDTypography>
            ),
            date: (
                <MDTypography variant="caption" color={darkMode ? "white" : "black"} fontWeight="medium">
                    {formatDateAndTime(row?.transaction_date).formattedDate || ''}
                </MDTypography>
            ),
            amount: (
                <MDTypography variant="caption" color={darkMode ? "white" : "black"} fontWeight="medium">
                    {row?.amount?.toFixed(0)}
                </MDTypography>
            ),
            status: (
                <MDBox ml={-1}>
                    <MDBadge badgeContent={row?.status} color={row?.status === "Success" || row?.status === "success" || row?.status === "SUCCESS" ? "success" : row?.status === "IN-PROCESS" || row?.status === "pending" ? "warning" : "error"} variant="gradient" size="sm" />
                </MDBox>
            ),
            utr: (
                <MDTypography variant="caption" color={darkMode ? "white" : "black"} fontWeight="medium">
                    {row?.utr}
                </MDTypography>
            ),
            txid: (
                <MDTypography variant="caption" color={darkMode ? "white" : "black"} fontWeight="medium">
                    {row?.transactionId}
                </MDTypography>
            ),
        }))
    }
}
