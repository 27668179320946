/* eslint-disable react/prop-types */
/* eslint-disable react/function-component-definition */
/**
=========================================================
* Material Dashboard 2 React - v2.2.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-dashboard-react
* Copyright 2023 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

// Material Dashboard 2 React components
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import MDAvatar from "components/MDAvatar";
import MDBadge from "components/MDBadge";

// Images
import agent from "assets/images/Agent.jpg"
export default function data(agents) {
    const Author = ({ image, name, email }) => (
        <MDBox display="flex" alignItems="center" lineHeight={1}>
            <MDAvatar src={image} name={name} size="sm" />
            <MDBox ml={2} lineHeight={1}>
                <MDTypography display="block" variant="button" fontWeight="medium">
                    {name}
                </MDTypography>
                <MDTypography variant="caption">{email}</MDTypography>
            </MDBox>
        </MDBox>
    );

    const Job = ({ title, description }) => (
        <MDBox lineHeight={1} textAlign="left">
            <MDTypography display="block" variant="caption" color="text" fontWeight="medium">
                {title}
            </MDTypography>
            <MDTypography variant="caption">{description}</MDTypography>
        </MDBox>
    );

    return {
        columns: [
            { Header: "Support Agents", accessor: "author", width: "45%", align: "left" },
            { Header: "status", accessor: "status", align: "center" },
            { Header: "employed", accessor: "employed", align: "center" },
            // { Header: "action", accessor: "action", align: "center" },
        ],
        rows: agents && agents.map((item, index) => ({
            author: <Author image={agent} name={item?.name} email={item?.emailId} />,
            status: (
                <MDBox ml={-1}>
                    <MDBadge badgeContent={item?.online ? "online" : "offline"} color={item?.online ? "success" : "dark"} variant="gradient" size="sm" />
                </MDBox>
            ),
            employed: (
                <MDTypography component="a" href="#" variant="caption" color="text" fontWeight="medium">
                    {item?.employedDate}
                </MDTypography>
            ),
            action: (
                <MDTypography component="a" href="#" variant="caption" color="text" fontWeight="medium">
                    Edit
                </MDTypography>
            ),
        }))
    };
}
