/**
=========================================================
* Material Dashboard 2 React - v2.2.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-dashboard-react
* Copyright 2023 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

// @mui material components
import Card from "@mui/material/Card";

// Material Dashboard 2 React components
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";

// Billing page components
import Gateway from "layouts/allGateway/gateway";
import PropTypes from "prop-types";
function GatewayInformation({ allGateways, getAllGatewaysfunc }) {
    return (
        <Card id="delete-account">
            <MDBox pt={3} px={2}>
                <MDTypography variant="h6" fontWeight="medium">
                    Gateway Information
                </MDTypography>
            </MDBox>
            <MDBox pt={1} pb={2} px={2}>
                <MDBox component="ul" display="flex" flexDirection="row" p={0} m={0} flexWrap="wrap">
                    {
                        allGateways && allGateways.map((item, index) => (
                            <Gateway
                                key={index}
                                gatewayName={item?.gatewayName}
                                abbr={item?.abbr}
                                collectionFee={item.collectionFee}
                                payoutFee={item.payoutFee}
                                // transactions={item?.transactions?.length}
                                // settlements={item?.settlements?.length}
                                func={getAllGatewaysfunc}
                                gatewayStatus={item?.switch}
                            />
                        ))
                    }
                </MDBox>
            </MDBox>
        </Card>
    );
}
GatewayInformation.propTypes = {
    allGateways: PropTypes.array,
    getAllGatewaysfunc: PropTypes.func

};
export default GatewayInformation;
