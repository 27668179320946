/* eslint-disable react/prop-types */
/* eslint-disable react/function-component-definition */
/**
=========================================================
* Material Dashboard 2 React - v2.2.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-dashboard-react
* Copyright 2023 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

// @mui material components
import MDTypography from "components/MDTypography";
import MDBox from "components/MDBox";
import MDBadge from "components/MDBadge";
import formatDateAndTime from "util/formatDateAndTime";
import { useMaterialUIController } from "context";
import { hideDigits } from "util/formatDateAndTime";
export default function data(transactions, activemerchant) {
    const [controller] = useMaterialUIController()
    const { darkMode } = controller
    return {
        columns: [
            { Header: "time", accessor: "time", align: "left" },
            { Header: "date", accessor: "date", align: "left" },
            { Header: "merchant", accessor: "merchant", align: "left" },
            { Header: "gateway", accessor: "gateway", align: "left" },
            { Header: "amount", accessor: "amount", align: "right" },
            { Header: "status", accessor: "status", align: "center" },
            { Header: "method", accessor: "method", align: "left" },
            { Header: "Ac.No./Upi-Id", accessor: "accountNumber", align: 'left', },
            { Header: "Ac.Name", accessor: "accountName", align: 'left', },
            { Header: "IFSC", accessor: "ifsc", align: 'left', },
            { Header: "bank", accessor: "bank", align: 'left', },
            { Header: "UTR", accessor: "utr", width: "10%", align: 'left', },
            { Header: "TXID", accessor: "txid", width: "10%", align: 'left', },
        ],

        rows: transactions && transactions.map((item, index) => ({
            time: (
                <MDTypography variant="caption" color={darkMode ? "white" : "black"} fontWeight="medium">
                    {formatDateAndTime(item?.transaction_date).formattedTime || ''}
                </MDTypography>
            ),
            date: (
                <MDTypography variant="caption" color={darkMode ? "white" : "black"} fontWeight="medium">
                    {formatDateAndTime(item.transaction_date).formattedDate || ''}
                </MDTypography>
            ),
            merchant: (
                <MDTypography variant="caption" color={darkMode ? "white" : "black"} fontWeight="medium">
                    {item?.business_name || activemerchant}
                </MDTypography>
            ),
            gateway: (
                <MDTypography variant="caption" color={darkMode ? "white" : "black"} fontWeight="medium">
                    {item?.gateway || ''}
                </MDTypography>
            ),
            amount: (
                <MDTypography variant="caption" color={darkMode ? "white" : "black"} fontWeight="medium">
                    {item?.amount || ''}
                </MDTypography>
            ),
            status: (
                <MDBox ml={-1}>
                    <MDBadge badgeContent={item?.status === "IN-PROCESS" ? "PENDING" : item.status === "fail" ? "FAILED" : item.status} color={item?.status === "Success" || item?.status === "success" || item?.status === "SUCCESS" ? "success" : item?.status === "IN-PROCESS" || item.status === "pending" ? "warning" : item?.status === "PENDING" ? "warning" : item.status === "expired" ? "info" : "error"} variant="gradient" size="sm" />
                </MDBox>
            ),
            method: (
                <MDTypography variant="caption" textTransform="uppercase" color={darkMode ? "white" : "black"} fontWeight="medium">
                    {item?.method || ''}
                </MDTypography>
            ),
            accountNumber: (
                <MDTypography variant="caption" color={darkMode ? "white" : "black"} fontWeight="medium">
                    {item?.method === "upi" ? item?.upiId ? item?.upiId : "" : item?.account_number ? hideDigits(item?.account_number) : ''}
                </MDTypography>
            ),
            accountName: (
                <MDTypography variant="caption" color={darkMode ? "white" : "black"} fontWeight="medium">
                    {item?.account_name || ''}
                </MDTypography>
            ),
            ifsc: (
                <MDTypography variant="caption" color={darkMode ? "white" : "black"} fontWeight="medium">
                    {item?.method === "upi" ? "N/A" : item?.ifsc_code || ''}
                </MDTypography>
            ),
            bank: (
                <MDTypography variant="caption" color={darkMode ? "white" : "black"} fontWeight="medium">
                    {item?.method === "upi" ? "N/A" : item?.bank_name || ''}
                </MDTypography>
            ),
            utr: (
                <MDTypography variant="caption" color={darkMode ? "white" : "black"} fontWeight="medium">
                    {item?.utr || ''}
                </MDTypography>
            ),
            txid: (
                <MDTypography variant="caption" color={darkMode ? "white" : "black"} fontWeight="medium">
                    {item?.transactionId || ''}
                </MDTypography>
            ),
        }))
    }
}
