import { useSandbox } from "ZustandState/useSanbox";
export const useConfig = () => {
    const { sandbox } = useSandbox();

    const config = {
        apiUrl: sandbox ? 'https://sandbox.payhub.link' : 'https://api.payhub.link',
        supportUrl: 'https://support-api.payhub.link',
    };

    return config;
}