import MDBox from 'components/MDBox'
import { Grid } from '@mui/material'
import MDTypography from 'components/MDTypography'
import ComplexStatisticsCard from 'examples/Cards/StatisticsCards/ComplexStatisticsCard'
import DashboardLayout from 'examples/LayoutContainers/DashboardLayout'
import DashboardNavbar from 'examples/Navbars/DashboardNavbar'
import React, { useEffect } from 'react'
import { useState } from 'react'
import { useMaterialUIController } from 'context'
import Transactions from "layouts/merchantSettlements/viewTransactions/transactions"
import { Formik, ErrorMessage, Field, Form } from 'formik'
import IconButton from '@mui/material/IconButton';
import MDInput from 'components/MDInput'
import SearchIcon from '@mui/icons-material/Search';
import { useLocation } from 'react-router-dom'
import Loader from 'util/Loader'
import { useConfig } from "../../../config"
import { useSandbox } from 'ZustandState/useSanbox'
function merchantTransactions() {
    const { apiUrl } = useConfig()
    const sandbox = useSandbox((state) => state.sandbox)
    const adminEmail = localStorage.getItem('admin_email');
    const adminApiKey = localStorage.getItem('admin_apiKey');
    const { state } = useLocation()
    const [controller] = useMaterialUIController()
    const { darkMode } = controller
    const [cards, setCards] = useState()
    const [transaction, setTransaction] = useState([])
    const [totalTansaction, setTotalTransaction] = useState([])
    const [page, setPage] = useState(0)
    const limit = 10
    const [check, setCheck] = useState("all")
    const [allDataLoading, setAllDataLoading] = useState(true)
    const initialSearchValues = {
        emailId: adminEmail || '',
        email_Id: state.emailId,
        apiKey: adminApiKey || '',
        utr: '',
    };
    const initialDateValues = {
        emailId: adminEmail || '',
        email_Id: state?.emailId || '',
        apiKey: adminApiKey || '',
        start_date: '',
        end_date: ''
    };
    const [formikValues, setFormikValues] = useState(initialDateValues);
    const formikRef = React.createRef();
    const handleResetForm = () => {
        if (formikRef.current) {
            formikRef.current.resetForm();
        }
    };
    // for upper cards 
    const getMerchant24hrData = async () => {
        try {
            let body = {
                emailId: adminEmail || '',
                apiKey: adminApiKey || '',
                email_Id: state.emailId
            }
            if (body.emailId === '' || body.apiKey === '') {
                return;
            }
            const response = await fetch(`${apiUrl}/admin/getlast24hourdata`, {
                method: 'POST',
                headers: {
                    //   'Authorization': 'Bearer YOUR_ACCESS_TOKEN',
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify(body)
            })

            if (!response) return;
            const res = await response.json()
            if (res.responseCode !== 200) return;
            setCards(res?.responseData)
        } catch (err) {
            console.log("Error Fetching Transactions: ", err)
        }
    }
    // for all trransaction of this merchant by pagination for table
    const getMerchantAllTransaction = async () => {
        let body = {
            emailId: adminEmail || '',
            apiKey: adminApiKey || '',
            email_Id: state?.emailId || '',
            limit,
            skip: limit * page
        }
        try {
            const response = await fetch(`${apiUrl}/admin/getUserTransactions`, {
                method: 'POST',
                headers: {
                    //   'Authorization': 'Bearer YOUR_ACCESS_TOKEN',
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify(body)
            })

            const res = await response.json()
            if (res.responseCode !== 200) return;
            setTotalTransaction(res?.responseData)
            setTransaction(res?.responseData)
        } catch (err) {
            console.log("Error Fetching Transactions: ", err)
        }
    }
    // With this we can find a single transaction by utr of this merchant
    const handleSearch = async (values, { setSubmitting }) => {
        try {
            if (values.emailId === '' || values.apiKey === '' || values.utr === '') {
                return;
            }
            const response = await fetch(`${apiUrl}/admin/getusertransactionbyutr`, {
                method: 'POST',
                headers: {
                    //   'Authorization': 'Bearer YOUR_ACCESS_TOKEN',
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify(values)
            })

            if (!response) return;
            const res = await response.json()
            if (res.responseCode !== 200) return;
            setTransaction(res.responseData)
        } catch (err) {
            console.log("Error Fetching Transactions: ", err)
        }
    }
    // with this we get date wise transaction of this merchant for table,this endpoint have pagination on backend 
    const handleDateSearch = async (values) => {
        try {
            if (values.start_date === "" || values.end_date === "" || values.emailId === "" || values.apiKey === "") {
                return;
            }
            const newValues = { ...values }
            newValues.limit = limit
            newValues.skip = page * limit
            setCheck("date")
            // setIsCsvReady(false)
            // setIsGeneratebtn(true)
            // setCsvCompleteData([])
            const response = await fetch(`${apiUrl}/admin/getmerchanttransactionbydate`, {
                method: 'POST',
                headers: {
                    //   'Authorization': 'Bearer YOUR_ACCESS_TOKEN',
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify(newValues)
            })
            const res = await response.json()
            if (res.responseCode !== 200) {
                setTransaction([])
                return;
            }
            setTransaction(res.responseData)
        } catch (err) {
            console.log("Error Fetching Transactions: ", err)
        }
    }
    useEffect(() => {
        setAllDataLoading(true)
        getMerchant24hrData().then(() => {
            setAllDataLoading(false)
        })
    }, [state, sandbox])
    useEffect(() => {
        if (check === "all") {
            getMerchantAllTransaction();
        } else if (check === "date") {
            handleDateSearch(formikValues);
        }
    }, [state, page, formikValues, sandbox]);

    return (
        <DashboardLayout>
            <DashboardNavbar />
            {
                allDataLoading ? (<Loader />) : (
                    <MDBox py={3}>
                        {cards && <Grid container spacing={3}>
                            <Grid item xs={12} md={2.4} lg={2.4}>
                                <MDBox mb={1.5}>
                                    <ComplexStatisticsCard
                                        color="7FDF9A"
                                        title="Today's Volume"
                                        count={Number(cards?.last24hr).toFixed(0)}

                                    />
                                </MDBox>
                            </Grid>
                            <Grid item xs={12} md={2.4} lg={2.4}>
                                <MDBox mb={1.5}>
                                    <ComplexStatisticsCard
                                        color="D4BEF0"
                                        title="Successful transactions"
                                        count={cards?.successfulTransactions}
                                    />
                                </MDBox>
                            </Grid>
                            <Grid item xs={12} md={2.4} lg={2.4}>
                                <MDBox mb={1.5}>
                                    <ComplexStatisticsCard
                                        color="BBF2EA"
                                        title="Success Rate"
                                        count={`${Math.round(cards?.successRate)}%`}
                                    />
                                </MDBox>
                            </Grid>
                            <Grid item xs={12} md={2.4} lg={2.4}>
                                <MDBox mb={1.5}>
                                    <ComplexStatisticsCard
                                        color="FFFED2"
                                        title="Yesterday's Volume"
                                        count={Number(cards?.yesterday).toFixed(0)}
                                    />
                                </MDBox>
                            </Grid>
                            <Grid item xs={12} md={2.4} lg={2.4}>
                                <MDBox mb={1.5}>
                                    <ComplexStatisticsCard
                                        color="C9DEDE"
                                        title="Balance Available"
                                        count={Number(cards?.balance).toFixed(0)}
                                    />
                                </MDBox>
                            </Grid>
                        </Grid>}

                        <MDBox mt={3}>
                            <MDBox display={'flex'} flexDirection={'row'}>
                                <Grid container spacing={3}>
                                    <Grid item xs={12} md={3} lg={3} xl={3}>
                                        <MDTypography variant="h6" color={darkMode ? "white" : "black"}>View Transactions</MDTypography>
                                        <MDTypography variant="caption" color={darkMode ? "white" : "black"}>Business: {state?.business_name}</MDTypography>
                                    </Grid>
                                    <Grid item xl={9}>
                                        <Grid container spacing={3}>
                                            <Grid item xs={12} md={3} lg={3}>
                                                <MDBox display={'flex'} flexDirection={'row'}>
                                                    {/* search input */}
                                                    <MDBox pr={1} >
                                                        <Formik
                                                            initialValues={initialSearchValues}
                                                            onSubmit={handleSearch}
                                                        >
                                                            {({ isSubmitting, setFieldValue }) => (
                                                                <Form>
                                                                    <MDBox
                                                                        display={'flex'}
                                                                        flexDirection={'row'}
                                                                    >
                                                                        <MDBox mb={2}>
                                                                            <MDTypography variant="caption" color="text" ml={1}>
                                                                                Search by UTR or TXID
                                                                            </MDTypography>
                                                                            <MDBox display={'flex'} flexDirection={'row'}>
                                                                                <Field
                                                                                    onChange={(e) => {
                                                                                        setFieldValue('utr', e.target.value)
                                                                                        if (initialSearchValues.utr === '') {
                                                                                            setTransaction(totalTansaction)
                                                                                        }
                                                                                    }}
                                                                                    type="text" as={MDInput} name="utr" />
                                                                                <IconButton type="submit">
                                                                                    <SearchIcon />
                                                                                </IconButton>
                                                                            </MDBox>
                                                                            <MDTypography color="error" variant="caption" >
                                                                                <ErrorMessage name="utr" component="div" />
                                                                            </MDTypography>
                                                                        </MDBox>
                                                                    </MDBox>
                                                                </Form>
                                                            )}
                                                        </Formik>
                                                    </MDBox>
                                                </MDBox>
                                            </Grid>
                                            <Grid item xs={12} md={3} lg={3}>
                                                {/* date input */}
                                                <MDBox pr={1} >
                                                    <Formik
                                                        initialValues={initialDateValues}
                                                        innerRef={formikRef}
                                                        enableReinitialize={true}
                                                    >
                                                        {({ isSubmitting, setFieldValue, values }) => (

                                                            <Form>
                                                                <MDBox
                                                                    display={'flex'}
                                                                    flexDirection={'row'}
                                                                >
                                                                    <MDBox mb={2}>
                                                                        <MDTypography variant="caption" color="text" ml={1}>
                                                                            Start date
                                                                        </MDTypography>
                                                                        <Field
                                                                            onChange={async (e) => {
                                                                                await setFieldValue('start_date', e.target.value)
                                                                                const newFormikValues = { ...formikValues, start_date: e.target.value };
                                                                                setFormikValues(newFormikValues);
                                                                                setPage(0)
                                                                                handleDateSearch(newFormikValues);
                                                                            }}
                                                                            type="date" as={MDInput} fullWidth name="start_date" />
                                                                        <MDTypography color="error" variant="caption" >
                                                                            <ErrorMessage name="start_date" component="div" />
                                                                        </MDTypography>
                                                                    </MDBox>
                                                                    <MDBox mb={2} ml={1}>
                                                                        <MDTypography variant="caption" color="text" ml={1}>
                                                                            End date
                                                                        </MDTypography>
                                                                        <Field
                                                                            onChange={async (e) => {
                                                                                await setFieldValue('end_date', e.target.value)
                                                                                const newFormikValues = { ...formikValues, end_date: e.target.value };
                                                                                setFormikValues(newFormikValues);
                                                                                setPage(0)
                                                                                handleDateSearch(newFormikValues);

                                                                            }}
                                                                            type="date" as={MDInput} fullWidth name="end_date" />
                                                                        <MDTypography color="error" variant="caption" >
                                                                            <ErrorMessage name="end_date" component="div" />
                                                                        </MDTypography>
                                                                    </MDBox>
                                                                </MDBox>
                                                            </Form>
                                                        )}
                                                    </Formik>
                                                </MDBox>
                                            </Grid>
                                        </Grid>
                                    </Grid>
                                </Grid>
                            </MDBox>
                        </MDBox>
                        <Transactions pages={page} pageSetter={setPage} transactions={transaction} />
                    </MDBox>
                )
            }


        </DashboardLayout>
    )
}

export default merchantTransactions