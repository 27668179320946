/* eslint-disable react/prop-types */
/* eslint-disable react/function-component-definition */
/**
=========================================================
* Material Dashboard 2 React - v2.2.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-dashboard-react
* Copyright 2023 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

// @mui material components
import MDTypography from "components/MDTypography";
import { useMaterialUIController } from "context";
export default function data(merchantDetails, func) {
    const [controller] = useMaterialUIController()
    const { darkMode } = controller
    return {
        columns: [
            { Header: "Merchant", accessor: "merchant", width: "10%", align: "left" },
            { Header: "Today's Volume", accessor: "todaysVolume", width: "10%", align: "right" },
            { Header: "Successful Tx", accessor: "transaction", width: "10%", align: "right" },
            { Header: "Yesterday's Volume", accessor: "yesterdaysVolume", width: "10%", align: "right" },
            { Header: "Yesterday's Tx", accessor: "yesterdaysTransaction", width: "10%", align: "right" },
            { Header: "Today's Fees", accessor: "todaysFees", width: "10%", align: "right" },
            { Header: "Yesterday's Fees", accessor: "yesterdaysFees", align: "right", width: "10%", },
            { Header: "Wallet Balance", accessor: "balance", align: "right", width: "10%", },
        ],

        rows: merchantDetails && merchantDetails.map((row, index) => ({
            merchant: (
                <MDTypography variant="caption" color={darkMode ? "white" : "black"} fontWeight="medium">
                    {func(row?.business_name)}
                </MDTypography>
            ),
            todaysVolume: (
                <MDTypography variant="caption" color={darkMode ? "white" : "black"} fontWeight="medium">
                    {row?.todayVolume?.toFixed(0)}
                </MDTypography>
            ),
            transaction: (
                <MDTypography variant="caption" color={darkMode ? "white" : "black"} fontWeight="medium">
                    {row.todayTransactions}
                </MDTypography>
            ),
            yesterdaysVolume: (
                <MDTypography variant="caption" color={darkMode ? "white" : "black"} fontWeight="medium">
                    {row?.yesterdayVolume?.toFixed(0)}
                </MDTypography>
            ),
            yesterdaysTransaction: (
                <MDTypography variant="caption" color={darkMode ? "white" : "black"} fontWeight="medium">
                    {row.yesterdayTransactions}
                </MDTypography>
            ),
            todaysFees: (
                <MDTypography variant="caption" color={darkMode ? "white" : "black"} fontWeight="medium">
                    {row?.todayFee?.toFixed(0)}
                </MDTypography>
            ),
            yesterdaysFees: (
                <MDTypography variant="caption" color={darkMode ? "white" : "black"} fontWeight="medium">
                    {row?.yesterdayFee?.toFixed(0)}
                </MDTypography>
            ),
            balance: (
                <MDTypography variant="caption" color={darkMode ? "white" : "black"} fontWeight="medium">
                    {row?.walletBalance?.toFixed(0)}
                </MDTypography>
            ),
        }))
    }
}
