/* eslint-disable react/prop-types */
/* eslint-disable react/function-component-definition */
/**
=========================================================
* Material Dashboard 2 React - v2.2.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-dashboard-react
* Copyright 2023 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

// @mui material components
import MDTypography from "components/MDTypography";
import MDBox from "components/MDBox";
import MDBadge from "components/MDBadge";
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';
import { capitalizeFirstLetter } from "util/formatDateAndTime";
import { Link } from "react-router-dom";
import { useMaterialUIController } from "context";
import DateRangeIcon from '@mui/icons-material/DateRange';
import MDButton from 'components/MDButton';
export default function data(merchantList) {
    const [controller] = useMaterialUIController()
    const { darkMode } = controller
    return {
        columns: [
            { Header: "Merchant Name", accessor: "merchantName", width: "15%", align: "left" },
            { Header: "Today's Volume", accessor: "todaysVolume", width: "15%", align: "right" },
            { Header: "Today's TX", accessor: "TodayTx", width: "15%", align: "right" },
            { Header: "Yesterday's Volume", accessor: "yesterdayVolume", width: "15%", align: "right" },
            // { Header: "View TX", accessor: "viewTx", width: "13%", align: "center" },
            { Header: "Payin Balance", accessor: "payinBalance", width: "15%", align: "right" },
            { Header: "Daily Logs", accessor: "dailyLogs", align: "center", width: "20%", },
            { Header: "Settle", accessor: "settle", align: "center", width: "30%", },
        ],

        rows: merchantList && merchantList.map((item, index) => ({
            merchantName: (
                <MDTypography variant="caption" color={darkMode ? "white" : "black"} fontWeight="medium">
                    {item && capitalizeFirstLetter(item?.merchant_name)}
                </MDTypography>
            ),
            todaysVolume: (
                <MDTypography variant="caption" color={darkMode ? "white" : "black"} fontWeight="medium">
                    {item && item?.todaysVolume?.toFixed(0)}
                </MDTypography>
            ),
            TodayTx: (
                <MDTypography variant="caption" color={darkMode ? "white" : "black"} fontWeight="medium">
                    {item && item?.todaysTransaction}
                </MDTypography>
            ),
            yesterdayVolume: (
                <MDTypography variant="caption" color={darkMode ? "white" : "black"} fontWeight="medium">
                    {item && item?.yesterdaysVolume?.toFixed(0)}
                </MDTypography>
            ),
            // viewTx: (
            //     <Link to={'/view-transaction'} state={{ emailId: item?.emailId, business_name: item?.merchant_name }}><InfoOutlinedIcon fontSize='small' color={darkMode ? "white" : "black"} /></Link>
            // ),
            payinBalance: item && (
                <MDTypography variant="caption" color={darkMode ? "white" : "black"} fontWeight="medium">
                    {Number(item?.balance)?.toFixed(0)}
                </MDTypography>
            ),
            dailyLogs: (
                <Link to={'/daily-logs'} state={{ emailId: item?.emailId, business_name: item?.merchant_name }}><DateRangeIcon fontSize='small' color={darkMode ? "white" : "black"} /></Link>
            ),
            settle: (
                <Link to={'/settle-merchant'} state={item?.emailId}>
                    <MDButton size="small" color={'success'}>Settle</MDButton>
                </Link>

            ),
        }))
    }
}
