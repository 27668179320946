import DashboardLayout from 'examples/LayoutContainers/DashboardLayout'
import React from 'react'

function Queries() {
    return (
        <DashboardLayout >
            <h1>Hello</h1>
        </ DashboardLayout >

    )
}

export default Queries