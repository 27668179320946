import DashboardLayout from 'examples/LayoutContainers/DashboardLayout'
import DashboardNavbar from 'examples/Navbars/DashboardNavbar'
import React, { useEffect, useState } from 'react'
import MDBox from 'components/MDBox'
import MDTypography from 'components/MDTypography'
import DefaultNavbar from './DefaultNavbar'
import { useMaterialUIController } from 'context'
import { Dialog, DialogContent, DialogTitle, Button, DialogActions } from '@mui/material';
import { Formik, Form, Field, ErrorMessage } from 'formik';
import MDButton from 'components/MDButton'
import * as Yup from 'yup';
import { toast } from 'react-toastify';
import MDInput from 'components/MDInput'
import { useNavigate } from 'react-router-dom'
import { useConfig } from "../../config"
import SupportTeam from './supportTeam'
import Queries from './queries'
import ComplexStatisticsCard from 'examples/Cards/StatisticsCards/ComplexStatisticsCard'
import { Grid } from '@mui/material'
import Loader from 'util/Loader'
function support() {
    const navigate = useNavigate()
    const { apiUrl, supportUrl } = useConfig()
    const [controller] = useMaterialUIController()
    const { darkMode } = controller
    const [openDialog, setOpenDialog] = useState(false);
    const [openClipboardDialog, setOpenClipboardDialog] = useState(false);
    const [cardsData, setCardsData] = useState()
    const [currentComponent, setCurrentComponent] = useState("SupportTeam")
    const [resFromFirstReq, setResFromFirstReq] = useState()
    const [isLoading, setIsLoading] = useState(true)
    const [callback, setCallback] = useState(true)
    const [agentData, setAgentData] = useState()
    const handleCloseDialog = () => {
        setOpenDialog(false);
    };
    const handleCloseClipboardDialog = () => {
        setOpenClipboardDialog(false);
    };
    const adminEmail = localStorage.getItem('admin_email');
    const adminApiKey = localStorage.getItem('admin_apiKey');
    const initialValues = {
        emailId: adminEmail || '',
        name: '',
        email_Id: '',
        password: '',
        employedDate: ''
    }
    const validationSchema = Yup.object({
        name: Yup.string().required('Name is required field'),
        email_Id: Yup.string().email("Invalid Email").required('Email is required field'),
        password: Yup.string().required("Password is required field"),
        employedDate: Yup.string(),
    });
    const handleAddSupport = async (values, { setSubmitting }) => {
        try {
            if (values.emailId === '') {
                toast.error("Invalid credential! Please Sign in again..", {
                    position: "top-right",
                    autoClose: 2000, // Auto close the toast after 3 seconds
                    hideProgressBar: false,
                });
                localStorage.removeItem('admin_token');

                // Navigate to the sign-in page
                navigate('/authentication/sign-in');
                return;
            }
            setAgentData(values)
            const response = await fetch(`${apiUrl}/support/addAgent`, {
                method: 'POST',
                headers: {
                    'apiKey': adminApiKey || '',
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify(values)
            });
            const res = await response.json();
            if (res.responseCode !== 200) {
                toast.error(res.responseMessage, {
                    position: "top-right",
                    autoClose: 2000, // Auto close the toast after 3 seconds
                    hideProgressBar: false,
                });
                return;
            }
            setResFromFirstReq(res?.responseData)
            let body2 = {
                emailId: values?.email_Id,
                name: values?.name,
                password: values?.password,
                employedDate: values?.employedDate,
                apiKey: res?.responseData?.apiKey
            }
            const response2 = await fetch(`${supportUrl}/api/support/add`, {
                method: 'POST',
                headers: {
                    'apiKey': adminApiKey || '',
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify(body2)
            });
            const res2 = await response2.json();
            if (res2.responseCode !== 200) {
                toast.error(res2.responseMessage, {
                    position: "top-right",
                    autoClose: 2000, // Auto close the toast after 3 seconds
                    hideProgressBar: false,
                });
                return;
            }
            let body3 = {
                emailId: adminEmail,
                email_Id: values?.email_Id,
                isVerified: true
            }
            const response3 = await fetch(`${apiUrl}/support/verifyAgent`, {
                method: 'POST',
                headers: {
                    'apiKey': adminApiKey || '',
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify(body3)
            });
            const res3 = await response3.json();
            if (res3.responseCode !== 200) {
                toast.error(res3.responseMessage, {
                    position: "top-right",
                    autoClose: 2000, // Auto close the toast after 3 seconds
                    hideProgressBar: false,
                });
                return;
            }
            toast.success(res2.responseMessage, {
                position: "top-right",
                autoClose: 2000, // Auto close the toast after 3 seconds
                hideProgressBar: false,
            });
            setSubmitting(false);
            setCallback(!callback)
            handleCloseDialog()
            setOpenClipboardDialog(true);
        } catch (error) {
            // Display an error toast
            toast.error('An error occurred. Please try again later.', {
                position: "top-right",
                autoClose: 2000,
                hideProgressBar: false,
            });
            // Reset submitting state
            setSubmitting(false);
        }
    };
    async function getCardsData() {
        try {
            const response = await fetch(`${supportUrl}/api/support/admin/analytics`, {
                method: 'GET',
                headers: {
                    'Content-Type': 'application/json',
                    'apiKey': adminApiKey
                }
            })
            if (!response) console.log("Something went wrong")
            const res = await response.json()
            setCardsData(res?.responseData)
        } catch (err) {
            console.log("Error Fetching getCardsData: ", err)
        }
    }
    useEffect(() => {
        getCardsData().then(() => {
            setIsLoading(false)
        })
    }, [])

    const handleCopyToClipboard = () => {
        const dataToCopy = `Email id: ${agentData.email_Id}\nPassword: ${agentData.password}\nAddress: support.payhub.link`;
        navigator.clipboard.writeText(dataToCopy);
        toast.success('Support data copied to clipboard', {
            position: "top-right",
            autoClose: 2000,
            hideProgressBar: false,
        });
    };
    return (
        <DashboardLayout>
            <DashboardNavbar />
            <DefaultNavbar currentComponentSetter={setCurrentComponent} openAddSupport={setOpenDialog} />
            {
                isLoading ? (<Loader />) : (
                    <MDBox pb={3}>
                        <MDBox mt={5}>
                            <Grid my container spacing={3}>
                                <Grid item xs={12} md={2.4} lg={2.4}>
                                    <MDBox mb={1.5}>
                                        <ComplexStatisticsCard
                                            color="7FDF9A"
                                            title="Today's Queries"
                                            count={cardsData?.todayQuery}
                                        />
                                    </MDBox>
                                </Grid>
                                <Grid item xs={12} md={2.4} lg={2.4}>
                                    <MDBox mb={1.5}>
                                        <ComplexStatisticsCard
                                            title="Yesterday's Queries"
                                            color="D4BEF0"
                                            count={cardsData?.yesterdayQuery}
                                        />
                                    </MDBox>
                                </Grid>
                                <Grid item xs={12} md={2.4} lg={2.4}>
                                    <MDBox mb={1.5}>
                                        <ComplexStatisticsCard
                                            color="BBF2EA"
                                            title="Open Queries"
                                            count={cardsData?.openQuery}
                                        />
                                    </MDBox>
                                </Grid>

                                <Grid item xs={12} md={2.4} lg={2.4}>
                                    <MDBox mb={1.5}>
                                        <ComplexStatisticsCard
                                            color="FFFED2"
                                            title="Assigned Queries"
                                            count={cardsData?.assignedQuery}
                                        />
                                    </MDBox>
                                </Grid>
                                <Grid item xs={12} md={2.4} lg={2.4}>
                                    <MDBox mb={1.5}>
                                        <ComplexStatisticsCard
                                            color="C9DEDE"
                                            title="Resolved Queries"
                                            count={cardsData?.resolvedQuery}
                                        />
                                    </MDBox>
                                </Grid>
                            </Grid>
                        </MDBox>

                        {
                            currentComponent === "SupportTeam" && <SupportTeam callback={callback} />
                        }
                        {
                            currentComponent === "Queries" && <Queries />
                        }


                        {/* test */}
                        {/* <MDBox mb={3}>
                            <Grid container spacing={3}>
                                <Grid item xs={12} md={12}>
                                    {
                                        !loading && (<MerchantInformation allMerchants={merchant} getAllMerchantsfunc={getAllMerchants} />)
                                    }
                                </Grid>
                            </Grid>
                        </MDBox> */}


                        {/* {
                        loading && (
                            <Loader />
                        )
                    } */}

                    </MDBox>
                )
            }


            <Dialog PaperProps={{
                style: {
                    minHeight: '35vh',
                    minWidth: '45vw',
                    background: darkMode ? "#344767" : "white",
                    boxShadow: '0px 10px 20px 0px rgba(0,0,0,0.2)', // Shadow effect
                    borderRadius: '10px', // Rounded corners
                    position: 'absolute',
                    // left: '50%', // Adjust the left value to move the dialog to the right
                },
            }} open={openDialog} onClose={handleCloseDialog}>
                <DialogTitle>ADD SUPPORT AGENT</DialogTitle>
                <DialogContent>
                    <Formik
                        initialValues={initialValues}
                        validationSchema={validationSchema}
                        onSubmit={handleAddSupport}
                    >
                        {({ isSubmitting }) => (
                            <Form>
                                <MDBox mb={2}>
                                    <Field type="text" label="Name" as={MDInput} fullWidth name="name" />
                                    <MDTypography color="error" variant="caption" >
                                        <ErrorMessage name="name" component="div" />
                                    </MDTypography>
                                </MDBox>
                                <MDBox mb={2}>
                                    <Field type="email" label="Email" as={MDInput} fullWidth name="email_Id" />
                                    <MDTypography color="error" variant="caption" >
                                        <ErrorMessage name="email_Id" component="div" />
                                    </MDTypography>
                                </MDBox>

                                <MDBox mb={0}>
                                    <Field type="password" label="Password" as={MDInput} fullWidth name="password" />
                                    <MDTypography color="error" variant="caption">
                                        <ErrorMessage name="password" component="div" />
                                    </MDTypography>
                                </MDBox>
                                <MDBox mb={2}>
                                    <MDTypography variant="caption" color="text" ml={1}>
                                        Employed Date
                                    </MDTypography>
                                    <Field type="date" as={MDInput} fullWidth name="employedDate" />
                                    <MDTypography color="error" variant="caption" >
                                        <ErrorMessage name="employedDate" component="div" />
                                    </MDTypography>
                                </MDBox>

                                <MDBox display={'flex'} flexDirection={'row'}>
                                    <MDBox>
                                        <MDButton
                                            type="submit" color="success" disabled={isSubmitting}
                                        >
                                            {isSubmitting ? 'Adding...' : 'Add Support'}
                                        </MDButton>
                                    </MDBox>
                                    <MDBox ml={3}>
                                        <MDButton
                                            onClick={handleCloseDialog} color="error"
                                        >
                                            Cancel
                                        </MDButton>
                                    </MDBox>
                                </MDBox>
                            </Form>
                        )}

                    </Formik>
                </DialogContent>

            </Dialog>

            <Dialog open={openClipboardDialog} onClose={handleCloseClipboardDialog}>
                <DialogTitle>Support Data</DialogTitle>
                <DialogContent>

                    <div>
                        <div>Email id: {agentData?.email_Id}</div>
                        <div>Password: {agentData?.password}</div>
                        <div>Address: support.payhub.link</div>
                    </div>
                </DialogContent>
                <DialogActions>
                    <Button onClick={handleCopyToClipboard} color="primary">
                        Copy to Clipboard
                    </Button>
                    <Button onClick={handleCloseClipboardDialog} color="primary">
                        Close
                    </Button>
                </DialogActions>
            </Dialog>
        </DashboardLayout>
    )
}

export default support