
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import Card from "@mui/material/Card";
import { Grid } from '@mui/material';
// import { makeStyles } from "@mui/styles"
import Loader from "util/Loader";
import DashboardLayout from 'examples/LayoutContainers/DashboardLayout'
import DashboardNavbar from 'examples/Navbars/DashboardNavbar'
// import { toast } from 'react-toastify';
import React, { useEffect, useState } from 'react'
// import { useUser } from 'ZustandState/useUser';
import { useConfig } from "../../../config";
import { useSandbox } from "ZustandState/useSanbox";
import MerchantInformation from "layouts/merchant/allMerchants/merchant-info";
function merchants() {
    const [merchant, setMerchants] = useState([])
    const { apiUrl } = useConfig()
    const sandbox = useSandbox((state) => state.sandbox)
    const adminEmail = localStorage.getItem('admin_email');
    const adminApiKey = localStorage.getItem('admin_apiKey');
    // const user = useUser((state) => state.user)
    const [loading, setLoading] = useState(true)
    async function getAllMerchants(body) {
        try {
            if (body.emailId === '' || body.apiKey === '') {
                return;
            }
            const response = await fetch(`${apiUrl}/admin/getallmerchantsdata`, {
                method: 'POST',
                headers: {
                    //   'Authorization': 'Bearer YOUR_ACCESS_TOKEN',
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify(body)
            })

            if (!response) console.log("Something went wrong")
            const res = await response.json()
            setMerchants(res?.responseData)
        } catch (err) {
            console.log("Error Fetching Merchants: ", err)
        }
    }
    useEffect(() => {
        setLoading(true)
        let body = {
            emailId: adminEmail || '',
            apiKey: adminApiKey || ''
        }
        getAllMerchants(body).then(() => {
            setLoading(false)
        })
    }, [sandbox])
    return (
        <DashboardLayout>
            <DashboardNavbar />
            <MDBox pb={3}>
                <Card>
                    <MDBox
                        variant="gradient"
                        bgColor="info"
                        borderRadius="lg"
                        coloredShadow="info"
                        mx={2}
                        mt={5}
                        p={2}
                        mb={1}
                        textAlign="center"
                    >
                        <MDTypography variant="h4" fontWeight="medium" color="white" mt={1}>
                            Merchants
                        </MDTypography>
                    </MDBox>

                    {/* test */}
                    <MDBox mb={3}>
                        <Grid container spacing={3}>
                            <Grid item xs={12} md={12}>
                                {
                                    !loading && (<MerchantInformation allMerchants={merchant} getAllMerchantsfunc={getAllMerchants} />)
                                }
                            </Grid>
                        </Grid>
                    </MDBox>

                </Card>
                {
                    loading && (
                        <Loader />
                    )
                }

            </MDBox>
        </DashboardLayout>

    )
}

export default merchants