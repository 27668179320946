/**
=========================================================
* Material Dashboard 2 React - v2.2.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-dashboard-react
* Copyright 2023 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

// @mui material components
import Grid from "@mui/material/Grid";
import Card from "@mui/material/Card";
// Material Dashboard 2 React components
import MDBox from "components/MDBox";
// Material Dashboard 2 React example components
import DataTable from "layouts/support/DataTable";
// Data
import supportTeamDataTable from "layouts/support/supportTeam/data/index";
import { useConfig } from "../../../config"
import { useState, useEffect } from "react";
import Loader from "util/Loader";
import PropTypes from "prop-types";
function SupportTeam({ callback }) {
    const { supportUrl } = useConfig()
    const adminApiKey = localStorage.getItem('admin_apiKey')
    const [agents, setAgents] = useState([])
    const [isLoading, setIsLoading] = useState(true)
    const getAllAgents = async () => {
        try {
            const response = await fetch(`${supportUrl}/api/support`, {
                method: 'GET',
                headers: {
                    //   'Authorization': 'Bearer YOUR_ACCESS_TOKEN',
                    apiKey: adminApiKey || '',
                    'Content-Type': 'application/json',
                },
            })
            const res = await response.json()
            if (res?.responseCode !== 200) return;
            setAgents(res?.responseData)
        } catch (err) {
            console.log("Error Fetching Agents: ", err)
        }
    }

    const { columns, rows } = supportTeamDataTable(agents);
    useEffect(() => {
        getAllAgents().then(() => {
            setIsLoading(false)
        })
    }, [callback])

    return (
        <MDBox pt={0} pb={3}>
            {
                isLoading ? (
                    <Loader />
                ) : (
                    <Grid mt={-4} container spacing={6}>
                        <Grid item xs={12}>
                            <Card >
                                <MDBox pt={3}>
                                    <DataTable
                                        table={{ columns, rows }}
                                        isSorted={false}
                                        entriesPerPage={false}
                                        showTotalEntries={false}
                                        noEndBorder
                                    />
                                </MDBox>
                            </Card>
                        </Grid>
                    </Grid>
                )
            }

        </MDBox>

    );
}
SupportTeam.propTypes = {
    callback: PropTypes.bool,
};

export default SupportTeam;
