import DataTable from '../DataTable';
import PropTypes from 'prop-types';
import dataFormat from "layouts/stats/merchantStats/data";
function MerchantStats(props) {
    const func = props.func
    const overview = props.merchantDetails
    const { columns, rows } = dataFormat(overview, func);
    return (
        <DataTable
            table={{ columns, rows }}
            showTotalEntries={false}
            isSorted={false}
            noEndBorder
            entriesPerPage={false}
        />
    );
}

MerchantStats.propTypes = {
    merchantDetails: PropTypes.array,
    func: PropTypes.func
};

export default MerchantStats;
