
import PropTypes from 'prop-types';
import DataTable from '../DataTable';
import dataFormat from "layouts/merchantSettlements/viewTransactions/transactions/data";

function transactions(props) {
    const { pages, pageSetter, transactions } = props
    const { columns, rows } = dataFormat(transactions);
    return (
        <DataTable
            table={{ columns, rows }}
            showTotalEntries={false}
            isSorted={false}
            noEndBorder
            entriesPerPage={false}
            pages={pages}
            pageSetter={pageSetter}
        />
    );
}

transactions.propTypes = {
    pages: PropTypes.number,
    pageSetter: PropTypes.func,
    transactions: PropTypes.array
};

export default transactions;
