import DashboardLayout from 'examples/LayoutContainers/DashboardLayout'
import DashboardNavbar from 'examples/Navbars/DashboardNavbar'
import React from 'react'
import MDTypography from 'components/MDTypography'
import { Grid } from '@mui/material'
import { useLocation, useNavigate } from 'react-router-dom'
import Paper from '@mui/material/Paper';
import MDButton from 'components/MDButton'
import Container from '@mui/material/Container';
import { Formik, Form, Field, ErrorMessage } from 'formik'
import MDInput from 'components/MDInput'
import MDBox from 'components/MDBox'
import * as Yup from 'yup';
import { toast } from 'react-toastify';
import { useConfig } from "../../../../config"
import { useMaterialUIController } from 'context'
import { useState } from 'react'
import { Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, Button } from '@mui/material';
import { useSandbox } from 'ZustandState/useSanbox'
function ResetPassword() {
    const [controller] = useMaterialUIController()
    const sandbox = useSandbox((state) => state.sandbox)
    const { darkMode } = controller
    const { state } = useLocation()
    const { apiUrl } = useConfig()
    const navigate = useNavigate();
    const [openDialog, setOpenDialog] = useState(false);
    const [responseData, setResponseData] = useState(null);
    const adminEmail = localStorage.getItem('admin_email')
    const adminApiKey = localStorage.getItem('admin_apiKey')
    const initialValues = {
        emailId: adminEmail || '',
        apiKey: adminApiKey || '',
        email_Id: state.email || '',
        newPassword: ''
    }
    const validationSchema = Yup.object({
        newPassword: Yup.string().required('New Password is required'),
    });
    const handleCancelClick = () => {
        // Go back to the previous page
        navigate(-1);
    };
    const handleSubmit = async (values, { setSubmitting }) => {
        try {
            if (sandbox) {
                toast.warning("Please turn off sandbox to update password..", {
                    position: "top-right",
                    autoClose: 2000, // Auto close the toast after 3 seconds
                    hideProgressBar: false,
                });
                return
            }
            if (values.emailId === '' || values.apiKey === '') {
                toast.error("Invalid credential! Please Sign in again..", {
                    position: "top-right",
                    autoClose: 2000, // Auto close the toast after 3 seconds
                    hideProgressBar: false,
                });
                localStorage.removeItem('admin_token');

                // Navigate to the sign-in page
                navigate('/authentication/sign-in');
                return;
            }
            const response = await fetch(`${apiUrl}/admin/resetMerchantPassword`, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify(values)
            });
            const res = await response.json();
            if (res.responseCode !== 200) {
                toast.error(res.responseMessage, {
                    position: "top-right",
                    autoClose: 2000, // Auto close the toast after 3 seconds
                    hideProgressBar: false,
                });
                return;
            }

            setSubmitting(false);
            toast.success(res.responseMessage, {
                position: "top-right",
                autoClose: 2000, // Auto close the toast after 3 seconds
                hideProgressBar: false,
            });
            const userBody = {
                emailId: values.email_Id,
                password: values.newPassword,
            }
            setResponseData(userBody);
            setOpenDialog(true);
            // navigate(-1)
        } catch (error) {
            // Display an error toast
            toast.error('An error occurred. Please try again later.', {
                position: "top-right",
                autoClose: 2000,
                hideProgressBar: false,
            });

            // Reset submitting state
            setSubmitting(false);
        }
    }
    const handleCloseDialog = () => {
        setOpenDialog(false);
    };
    const handleCopyToClipboard = () => {

        const dataToCopy = `Address: www.payhub.link\nEmail id: ${responseData.emailId}\nNew Password: ${responseData.password}`;
        navigator.clipboard.writeText(dataToCopy);
        toast.success('Merchant data copied to clipboard', {
            position: "top-right",
            autoClose: 2000,
            hideProgressBar: false,
        });
    };
    return (
        <DashboardLayout>
            <DashboardNavbar />
            <Container component="main" maxWidth="sm" sx={{ mb: 4 }}>
                <Paper variant="outlined" sx={{ my: { xs: 3, md: 6 }, p: { xs: 2, md: 3 }, backgroundColor: darkMode ? '#344767' : 'white' }}>
                    <MDTypography variant="h6" gutterBottom mb={4}>
                        Reset Merchant Password
                    </MDTypography>
                    <Formik
                        initialValues={initialValues}
                        validationSchema={validationSchema}
                        onSubmit={handleSubmit}
                    >
                        {({ isSubmitting }) => (
                            <Form>

                                <Grid item lg={12} xl={12} sm={12} xs={12}>
                                    <MDBox mb={2}>
                                        <MDTypography color={darkMode ? "white" : "black"} fontweight="medium" variant="h6" >
                                            Business:  {state?.business}
                                        </MDTypography>
                                    </MDBox>
                                    <MDBox mb={2}>
                                        <Field type="text" label="New Password" as={MDInput} fullWidth name="newPassword" />
                                        <MDTypography color="error" variant="caption" >
                                            <ErrorMessage name="newPassword" component="div" />
                                        </MDTypography>
                                    </MDBox>
                                </Grid>
                                <MDBox mt={4} mb={1} justifyContent="right">
                                    <Grid container justifyContent="right">
                                        <Grid item display={'flex'}>
                                            <MDBox>
                                                <MDButton
                                                    variant="gradient"
                                                    color="info"
                                                    type="submit"
                                                    disabled={isSubmitting}
                                                >
                                                    {isSubmitting ? 'Saving...' : 'Save'}
                                                </MDButton>
                                            </MDBox>
                                            <MDBox ml={2}>
                                                <MDButton
                                                    variant="gradient"
                                                    color="error"
                                                    onClick={handleCancelClick}
                                                >
                                                    Cancel
                                                </MDButton>
                                            </MDBox>
                                        </Grid>
                                    </Grid>
                                </MDBox>

                            </Form>
                        )}
                    </Formik>
                </Paper>
            </Container>
            <Dialog open={openDialog} onClose={handleCloseDialog}>
                <DialogTitle>Merchant Data</DialogTitle>
                <DialogContent>
                    <div>
                        <div>Address: www.payhub.link</div>
                        <div>Email id: {state?.email}</div>
                        <div>New Password: {responseData?.password}</div>
                    </div>
                </DialogContent>
                <DialogActions>
                    <Button onClick={handleCopyToClipboard} color="primary">
                        Copy to Clipboard
                    </Button>
                    <Button onClick={handleCloseDialog} color="primary">
                        Close
                    </Button>
                </DialogActions>
            </Dialog>
        </DashboardLayout>
    )
}

export default ResetPassword