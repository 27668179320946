/* eslint-disable react/prop-types */
/* eslint-disable react/function-component-definition */
/**
=========================================================
* Material Dashboard 2 React - v2.2.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-dashboard-react
* Copyright 2023 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

// @mui material components
import MDTypography from "components/MDTypography";
import { capitalizeFirstLetter } from "util/formatDateAndTime";
import { Link } from "react-router-dom";
import { useMaterialUIController } from "context";
import MDButton from 'components/MDButton';
export default function data(gatewayList) {
    const [controller] = useMaterialUIController()
    const { darkMode } = controller
    return {
        columns: [
            { Header: "Gateway Name", accessor: "gatewayName", width: "10%", align: "left" },
            { Header: "Today's Volume", accessor: "todaysVolume", width: "10%", align: "right" },
            { Header: "Today's TX", accessor: "TodayTx", width: "10%", align: "right" },
            { Header: "Yesterday's Volume", accessor: "yesterdayVolume", width: "13%", align: "right" },
            { Header: "Yesterday's Tx", accessor: "yesterdayTx", width: "10%", align: "right" },
            { Header: "Today's Balance", accessor: "balance", width: "10%", align: "right" },
            { Header: "Settle", accessor: "settle", align: "center", width: "10%", },
        ],

        rows: gatewayList && gatewayList.map((row, index) => ({
            gatewayName: (
                <MDTypography textTransform="uppercase" variant="caption" color={darkMode ? "white" : "black"} fontWeight="medium">
                    {capitalizeFirstLetter(row?.gatewayName)}
                </MDTypography>
            ),
            todaysVolume: (
                <MDTypography variant="caption" color={darkMode ? "white" : "black"} fontWeight="medium">
                    {Number(row?.last24hr).toFixed(0)}
                </MDTypography>
            ),
            TodayTx: (
                <MDTypography variant="caption" color={darkMode ? "white" : "black"} fontWeight="medium">
                    {row?.last24hrSuccess}
                </MDTypography>
            ),
            yesterdayVolume: (
                <MDTypography variant="caption" color={darkMode ? "white" : "black"} fontWeight="medium">
                    {Number(row?.yesterday).toFixed(0)}
                </MDTypography>
            ),
            yesterdayTx: (
                <MDTypography variant="caption" color={darkMode ? "white" : "black"} fontWeight="medium">
                    {row?.yesterdayTransactions}
                </MDTypography>
            ),
            balance: (
                <MDTypography variant="caption" color={darkMode ? "white" : "black"} fontWeight="medium">
                    {Number(row?.todaysBalance).toFixed(0)}
                </MDTypography>
            ),
            settle: (
                <Link to={'/settle-gateway'} state={row?.gatewayName}><MDButton size="small" color={'success'}>Settle</MDButton></Link>
            ),
        }))
    }
}
